import styled from "@emotion/styled";
import type { FC, PropsWithChildren } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import { ErrorCode } from "src/constants/errorCodes";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import type Path from "src/constants/path";
import { BiometricVerificationBloc } from "src/hybrid/components/BiometricVerification";
import { cleanEmail } from "src/lib/cleanEmail";
import { FeatureFlags } from "src/lib/featureFlags";
import { isHybridApp } from "src/lib/platform";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import { useBloc } from "src/state/state";
import Link from "src/ui/components/Link/Link";
import Loader from "src/ui/components/Loader/Loader";
import SignupWrapper from "src/ui/components/SignupWrapper/SignupWrapper";
import Track from "src/ui/components/Track/Track";
import Translate from "src/ui/components/Translate/Translate";
import LoginOptions from "./LoginOptions";
import {
  AutoForm,
  AutoFormCheckboxGroupField,
  AutoFormInputField,
  Button,
  Checkbox,
  useAutoFormControls
} from "@9amhealth/shared";
import { z } from "zod";

export enum LoginView {
  loginOptions = "loginOptions",
  loginForm = "loginForm"
}

interface FormValues {
  email: string;
  password: string;
  mfa?: number;
  rememberMe?: string[];
}

interface Props {
  onSuccess?: () => unknown;
  resetPasswordLink: Path | (() => unknown);
  registerLink: Path | (() => unknown);
  noFrame?: boolean;
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: ${isHybridApp() ? "left" : "right"};
  align-items: center;
  width: inherit;
`;

const Wrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Placeholder: FC<PropsWithChildren> = ({ children }) => (
  <div>{children}</div>
);

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(12),
  mfa: z.number().optional(),
  rememberMe: z.array(z.literal("rememberMe")).optional()
});

const LoginForm: FC<Props> = ({
  onSuccess,
  resetPasswordLink,
  registerLink,
  noFrame = false
}) => {
  const [, { login }] = useBloc(AuthenticationBloc);
  const [flags, { getFlag }] = useBloc(FeatureFlags);
  const [requiresMfa, setRequiresMfa] = React.useState(false);

  const params = new URLSearchParams(document.location.search);
  const view = params.get("view");
  const location = useLocation();

  const [
    { isBiometricVerificationEnabled, credentialsAvailable },
    { biometricTypeName, getCredentials }
  ] = useBloc(BiometricVerificationBloc);

  const loginView = React.useMemo(() => {
    const enabled = !isHybridApp() && getFlag("sso_login_option_transcarent");
    if (enabled && view !== LoginView.loginForm) {
      return LoginView.loginOptions;
    }
    return LoginView.loginForm;
  }, [view, isHybridApp, location, flags]);

  const [failed, setFailed] = React.useState(false);
  const handleMfaRequiredResponse = (): void => {
    setRequiresMfa(true);

    requestAnimationFrame(() => {
      const mfaInput = document.querySelector("input[name=mfa]");

      if (mfaInput && mfaInput instanceof HTMLInputElement) {
        mfaInput.focus();
      }
    });
  };
  const [errPassword, setErrPassword] = React.useState("");

  const loginAction = async ({
    email,
    password,
    mfa
  }: FormValues): Promise<boolean> => {
    let success = false;
    let code = "0";

    try {
      setFailed(false);
      setErrPassword("");
      if (password.length < 12) {
        setFailed(true);
        setErrPassword("password_invalid");
        return false;
      }

      await login({
        email: cleanEmail(email),
        password,
        mfa: mfa ? String(mfa) : undefined
      });
      success = true;
    } catch (error) {
      setErrPassword("login_credentials");
      const apiError = error as {
        body?: {
          code?: number | string;
        };
      };
      code = String(apiError.body?.code ?? 0);
      if (code !== ErrorCode.twoFactorAuthRequired) {
        reportErrorSentry(error);
        setFailed(true);
      }
      success = false;
    }

    if (success) {
      onSuccess?.();
    }
    if (code === ErrorCode.twoFactorAuthRequired) {
      handleMfaRequiredResponse();
      return false;
    }
    return success;
  };

  const loginWithBiometrics = async (): Promise<void> => {
    const credentials = await getCredentials();
    if (credentials?.password && credentials.username) {
      await loginAction({
        email: credentials.username,
        password: credentials.password
      });
    }
  };

  const Frame = noFrame ? Placeholder : SignupWrapper;

  const passwordLinkProps = {
    to: typeof resetPasswordLink === "function" ? "#" : resetPasswordLink,
    onClick:
      typeof resetPasswordLink === "function" ? resetPasswordLink : undefined
  };

  const registerLinkProps = {
    to: typeof registerLink === "function" ? "#" : registerLink,
    onClick: typeof registerLink === "function" ? registerLink : undefined
  };

  if (isHybridApp()) {
    // Register link should open in browser for hybrid apps
    registerLinkProps.to = "#";
    registerLinkProps.onClick = (): void => {
      reportErrorSentry(new Error("Register link clicked in hybrid app"));
    };
  }

  const autoFormControls = useAutoFormControls({
    schema,
    initialValue: { rememberMe: isHybridApp() ? ["rememberMe"] : [] },
    onSubmit: (values: FormValues): void => {
      StorageController.switchStorageTo(
        values.rememberMe?.includes("rememberMe")
          ? localStorage
          : sessionStorage
      );
      void loginAction({
        email: values.email,
        password: values.password,
        mfa: values.mfa
      });
    }
  });

  return (
    <Frame loadingKey={LoadingKey.register} hideBottomNavbar>
      <Track event={TrackEvent.login} type={TrackType.start} />
      <Loader loadingKey={LoadingKey.login}>
        <Wrapper>
          <div style={{ marginTop: "auto" }}>
            {loginView === LoginView.loginOptions ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <LoginOptions />
              </div>
            ) : (
              <div style={{ marginTop: "auto" }}>
                <nine-heading
                  style={{
                    "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
                  }}
                >
                  <h3 className="as-h4-large">
                    <Translate msg="signin" variables={{ context: "email" }} />
                  </h3>
                  <nine-spacer s="sm"></nine-spacer>
                  <p className="m0 color-c-80">
                    <Translate
                      msg={"login.subtitle"}
                      variables={{ context: "manage" }}
                    />
                  </p>
                </nine-heading>

                <nine-spacer s="xl"></nine-spacer>
                <AutoForm {...autoFormControls.props}>
                  <FormFieldsWrapper>
                    <AutoFormInputField
                      name="email"
                      label={translate("emailAddress")}
                      isRequired
                      type="email"
                      isDisabled={requiresMfa}
                    ></AutoFormInputField>
                    <AutoFormInputField
                      isPasswordToggleEnabled
                      name="password"
                      label={translate("password")}
                      isRequired
                      type="password"
                      error={!requiresMfa && failed ? errPassword : ""}
                      isDisabled={requiresMfa}
                    ></AutoFormInputField>

                    <Row>
                      {!isHybridApp() && (
                        <AutoFormCheckboxGroupField name="rememberMe">
                          <Checkbox name="rememberMe" value="rememberMe">
                            <Translate msg="rememberMe" />
                          </Checkbox>
                        </AutoFormCheckboxGroupField>
                      )}
                      <LinkWrapper>
                        <Link
                          className="color-c-80"
                          style={{
                            fontWeight: 400,
                            pointerEvents: requiresMfa ? "none" : "auto",
                            opacity: requiresMfa ? 0.4 : 1
                          }}
                          {...passwordLinkProps}
                        >
                          <Translate msg="forgot_password" />
                        </Link>
                      </LinkWrapper>
                    </Row>
                    {requiresMfa && (
                      <>
                        <nine-spacer s="xxxs" />
                        <AutoFormInputField
                          name="mfa"
                          label={translate("verificationCode")}
                          autoComplete="one-time-code"
                          isRequired
                          description={translate("mfa.help")}
                          error={failed ? "login_credentials" : ""}
                          mask="000000"
                          type="tel"
                        ></AutoFormInputField>
                      </>
                    )}
                  </FormFieldsWrapper>

                  <nine-content>
                    <div className="center">
                      <nine-spacer s="xl"></nine-spacer>
                      <Actions>
                        {requiresMfa && (
                          <Button
                            theme="transparent"
                            hideArrow
                            onPress={() => {
                              setRequiresMfa(false);
                            }}
                          >
                            <Translate msg="cancel" />
                          </Button>
                        )}
                        <Button type="submit">
                          <Translate msg="login" />
                        </Button>
                      </Actions>

                      <nine-spacer s="lg"></nine-spacer>

                      {isBiometricVerificationEnabled &&
                        credentialsAvailable && (
                          <>
                            <Button
                              outline
                              theme="charcoal"
                              onPress={(): void => void loginWithBiometrics()}
                            >
                              <Translate
                                msg="login.with"
                                variables={{ context: biometricTypeName }}
                              />
                            </Button>
                            <nine-spacer s="lg"></nine-spacer>
                          </>
                        )}
                    </div>
                  </nine-content>
                </AutoForm>
              </div>
            )}
          </div>

          <nine-content style={{ marginTop: "auto" }}>
            <div className="center">
              {!isHybridApp() && !requiresMfa && (
                <>
                  <p className="m0 color-c-80">
                    <Translate msg="register.alternative.question" />
                  </p>
                  <nine-spacer s="xxxs"></nine-spacer>
                  <nine-spacer s="xxxs"></nine-spacer>
                  <Link className="color-c-80" {...registerLinkProps}>
                    <Translate msg="register.alternative.link" />
                  </Link>
                </>
              )}
            </div>
          </nine-content>
        </Wrapper>
      </Loader>
    </Frame>
  );
};

export default LoginForm;
