import type { SanityImageAssetDocument } from "@sanity/client";
import { Cubit } from "blac";
import SanityService from "src/api/SanityService";
import { globalEvents } from "src/constants/globalEvents";
import reportErrorSentry from "src/lib/reportErrorSentry";
import sanityQuery from "src/lib/sanityQuery";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { loadingState } from "src/state/state";
import type {
  CmsCareTeamMember,
  EstablishCareStepSettings
} from "src/types/sanitySchema";

export interface SanityState<T> {
  data: T[];
  isError?: boolean;
  availableList: Set<string>;
}

export default class SanityCubit<T> extends Cubit<SanityState<T>> {
  constructor() {
    super({ data: [], availableList: new Set() });

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({ data: [], availableList: new Set() });
    });
  }

  readonly fetchFirst = async <E>(query: string): Promise<E | undefined> => {
    loadingState.start(LoadingKey.sanityData);
    let fetchData: E | undefined = undefined;

    try {
      const data = await SanityService.fetchSanityData<T>(query);

      this.emit({
        ...this.state,
        data: [data[0]]
      });

      fetchData = data[0] as unknown as E;
    } catch (e: unknown) {
      reportErrorSentry(e);

      this.emit({
        ...this.state,
        isError: true
      });
    }

    loadingState.finish(LoadingKey.sanityData);
    return fetchData;
  };

  readonly fetchAll = async (query: string): Promise<void> => {
    loadingState.start(LoadingKey.sanityData);

    try {
      const data = await SanityService.fetchSanityData<T>(query);

      this.emit({
        ...this.state,
        data
      });
    } catch (e: unknown) {
      reportErrorSentry(e);

      this.emit({
        ...this.state,
        isError: true
      });
    }

    loadingState.finish(LoadingKey.sanityData);
  };

  get firstElement(): T | undefined {
    return this.state.data[0];
  }

  /**
   * Fetches the care team details for a given user ID, loads from sanity if not already loaded
   * @param id care team user ID
   * @returns undefined while loading and then the care team details
   */
  public readonly fetchCareTeamDetails = (
    id: string
  ): CmsCareTeamMember | undefined => {
    // return cached details
    if (this.careTeamDetails && this.careTeamDetailsUserId === id) {
      return this.careTeamDetails;
    }

    // return undefined if we're already fetching or if no ID is provided
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (this.careTeamPromise || !id) {
      return;
    }

    // create the promise to fetch the details and cache it
    const promise = (async () => {
      // update state
      if (this.state.availableList.has(id)) {
        this.state.availableList.delete(id);
        this.emit({ ...this.state });
      }

      this.careTeamDetailsUserId = id;
      const sanityData = await this.fetchFirst<CmsCareTeamMember>(
        sanityQuery.getCareTeamMembersByUserIds(`"${id}"`)
      );
      const assetList = SanityService.createResolveRefList();
      if (sanityData?.image?.asset._ref) {
        assetList.add(sanityData.image.asset._ref, (data) => {
          sanityData.resolvedImage = data as SanityImageAssetDocument;
        });
      }
      if (sanityData?.portrait?.asset._ref) {
        assetList.add(sanityData.portrait.asset._ref, (data) => {
          sanityData.resolvedPortrait = data as SanityImageAssetDocument;
        });
      }
      await assetList.resolve();

      if (sanityData) {
        this.careTeamDetails = sanityData;
      } else {
        this.careTeamDetails = { _id: id, error: "No data found" };
      }
      this.careTeamPromise = undefined;

      // update state
      this.state.availableList.add(id);
      this.emit({ ...this.state });

      return sanityData;
    })();

    // cache the promise
    this.careTeamPromise = promise;
  };
  careTeamDetailsUserId: string | undefined;
  careTeamPromise: Promise<CmsCareTeamMember | undefined> | undefined;
  careTeamDetails: CmsCareTeamMember | undefined;

  /**
   * Fetches care team members array from "Establish Care Step Settings"
   * @returns List of care team meembers
   */
  public readonly fetchEstablishCareStepSettings = ():
    | EstablishCareStepSettings
    | undefined => {
    if (this.establishCareStepSettings) {
      return this.establishCareStepSettings;
    }

    if (this.establishCareStepPromise) {
      return;
    }

    const promise = (async () => {
      const sanityData = await this.fetchFirst<EstablishCareStepSettings>(
        sanityQuery.getEstablishCareStepSettings()
      );

      if (sanityData) {
        this.establishCareStepSettings = sanityData;
      } else {
        reportErrorSentry(
          new Error(`No data found for establish care step settings`)
        );
        this.establishCareStepSettings = undefined;
      }

      this.establishCareStepPromise = undefined;

      return sanityData;
    })();

    this.establishCareStepPromise = promise;
  };
  establishCareStepSettings: EstablishCareStepSettings | undefined;
  establishCareStepPromise:
    | Promise<EstablishCareStepSettings | undefined>
    | undefined;
}
