import type { URLOpenListenerEvent } from "@capacitor/app";
import { App } from "@capacitor/app";
import type { FC } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { appViewState, historyState } from "src/state/state";

const AppUrlListener: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      let slug: string | undefined = "";
      if (event.url.includes(".health")) {
        slug = event.url.split(".health").pop();
      }
      if (event.url.includes("health.com")) {
        slug = event.url.split("health.com").pop();
      }
      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    }).catch((err: unknown) => {
      reportErrorSentry(err);
    });
  }, []);

  useEffect(() => {
    historyState.locationChange(location);
    appViewState.navigate = navigate;
  }, [location]);

  return null;
};

export default AppUrlListener;
