import { ChartComposedProps } from "molecule/chart/Chart";
import React from "react";
import {
  CartesianGrid,
  Line,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar
} from "recharts";
import {
  ChartBaseBar,
  ChartBaseLine,
  ChartBaseLineChart,
  ChartBaseReferenceLine,
  ChartBaseTooltip,
  ChartBaseXAxis,
  ChartBaseYAxis,
  ChartLineProps
} from "./ChartBaseConfig";

export type ChartLine = {
  color: string;
  dataKey: string;
  props?: ChartLineProps;
};

function ChartComposed<T>({
  chartData,
  type,
  lines,
  bars,
  tooltip,
  yAxis = {},
  xAxis = {},
  referenceLines = []
}: ChartComposedProps<T>) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={chartData} {...ChartBaseLineChart}>
        <CartesianGrid syncWithTicks={true} />

        <XAxis {...ChartBaseXAxis} {...xAxis} />
        <YAxis {...ChartBaseYAxis} {...yAxis} />
        {referenceLines.map((line, i) => (
          <ReferenceLine
            key={`reference-line-${i.toString()}`}
            {...ChartBaseReferenceLine}
            {...line}
          />
        ))}
        {tooltip && <Tooltip {...ChartBaseTooltip<T>(tooltip)} />}
        {type === "line" &&
          lines &&
          lines.map((line) => (
            <Line
              key={line.dataKey}
              {...ChartBaseLine({ color: line.color })}
              dataKey={line.dataKey}
              {...line.props}
            />
          ))}

        {type === "bar" &&
          bars &&
          bars.map((bar) => (
            <Bar
              barSize={chartData.length < 10 ? 20 : undefined}
              key={bar.dataKey}
              {...ChartBaseBar({ color: bar.color, dataKey: bar.dataKey })}
            />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default ChartComposed;
