import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { authenticationState, useBloc } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import reportErrorSentry from "./reportErrorSentry";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";

export const SAML_FUNNEL_KEY_SESSION_STORAGE_KEY = "samlFunnel";

const useSamlAuth = (): { loading: boolean; error: TranslationKey | null } => {
  const { pathname } = useLocation();
  const [query] = useSearchParams();
  const [error, setError] = useState<TranslationKey | null>(null);
  const token = query.get("token");
  const funnel = query.get("funnel");
  const pathMatchLogin = pathname.startsWith("/auth/login");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [, { getUnfinishedStepPath }] = useBloc(SubscriptionCubit);

  useEffect(() => {
    if (token && pathMatchLogin) {
      BlockingLoadingOverlayController.startLoading({
        bg: "branded"
      });
      setLoading(true);

      if (funnel) {
        sessionStorage.setItem(SAML_FUNNEL_KEY_SESSION_STORAGE_KEY, funnel);
      }

      void authenticationState
        .authWithSamlToken(token)
        .then(async () => {
          const path = await getUnfinishedStepPath();
          navigate(
            path?.includes("success") || !path ? "/app/home?from=saml" : path
          );
        })
        .catch((e: unknown) => {
          reportErrorSentry(e);
          setError("samltoken.error");
        })
        .finally(() => {
          BlockingLoadingOverlayController.endLoading();
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [token, pathMatchLogin, funnel]);

  return {
    error,
    loading: Boolean(token && loading)
  };
};

export default useSamlAuth;
