import { Cubit } from "blac-next";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import envVariables from "src/lib/envVariables";
import { getSupportedUserLanguage } from "src/lib/i18next";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { subscriptionState } from "../state";
import { Language } from "src/constants/language";
import {
  Feed,
  FeedData
} from "src/ui/components/UserEducationalFeed/UserEducationalFeedBloc";

type RemoteConfigState = {
  callbackAvailable?: boolean;
  scheduleOnboardingCallUrl?: string;
};

export type JourneyContentLessonItem = {
  title: string;
  type: "typeform" | "article";
  typeformId?: string;
  url?: string;
  taskSlug?: string;
};

export type JourneyContentLesson = {
  name: string;
  taskGroup?: string;
  items: JourneyContentLessonItem[];
};

export type JourneyContentClass = {
  name: string;
  subtitle: string;
  taskGroupReference: string;
  listOfLessons: JourneyContentLesson[];
};

export type JourneyContentResponse = {
  name: string;
  taskProgramReference: string;
  listOfClassesContentAvailable: JourneyContentClass[];
  type: "task-based";
  _id: string;
};

type JourneyData = {
  journey: JourneyContentResponse;
};

export class AppRemoteConfigCubit extends Cubit<RemoteConfigState> {
  apiRoot = envVariables.CMS_API_BASE_URL;

  constructor() {
    super({});
  }

  fetchMap: Map<string, { time: Date; promise: Promise<unknown> }> = new Map();

  loadJourneyContent = async (
    journey: string,
    params?: {
      language?: Language;
    }
  ): Promise<JourneyData> => {
    const { language = getSupportedUserLanguage() } = params || {};
    const url = new URL(this.apiRoot);
    url.pathname = "/api/educational/program";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        language,
        journey,
        environment: envVariables.APP_ENV
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to download educational content: ${errorText}`);
    }

    const responseJson = (await response.json()) as { data: JourneyData };
    const data: JourneyData = responseJson.data;
    return data;
  };

  loadEducationalContent = async (params: {
    language?: Language;
    date?: string;
    enrolled?: string;
    tasks?: {
      completed?: string[];
      available?: string[];
    };
    programs?: string[];
    conditions?: string[];
    curated?: { id: string; date?: string }[];
  }): Promise<FeedData> => {
    const url = new URL(this.apiRoot);
    url.pathname = "/api/educational/feed";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to download educational content: ${errorText}`);
    }

    const responseJson = (await response.json()) as Feed;
    const data: FeedData = responseJson.data;
    return data;
  };

  fetchAvailability = async (): Promise<void> => {
    const cached = this.fetchMap.get("availability");
    const maxAge = 60 * 1000 * 5; // 5 minutes
    if (cached && new Date().getTime() - cached.time.getTime() < maxAge) {
      await cached.promise;
      return;
    }

    const promise = new Promise((resolve, reject) => {
      const url = new URL(this.apiRoot);
      url.pathname = "api/availability";

      const { partnerFunnel } = subscriptionState.activeSubscriptionDetails;
      if (partnerFunnel) {
        url.searchParams.set("payer", partnerFunnel);
      }
      url.searchParams.set("language", getSupportedUserLanguage());

      fetch(url)
        .then((response) => {
          if (response.ok) {
            response
              .json()
              .then((json) => {
                const data = json as {
                  callbackAvailableNow: boolean;
                  scheduleOnboardingCallCalendlyUrl: string;
                };
                this.emit({
                  callbackAvailable: data.callbackAvailableNow,
                  scheduleOnboardingCallUrl:
                    data.scheduleOnboardingCallCalendlyUrl
                });
                resolve(null);
              })
              .catch((e2: unknown) => {
                reportErrorSentry(new Error("invalid response"));
                reject(e2 as Error);
              });
          }
        })
        .catch((e: unknown) => {
          addSentryBreadcrumb(
            "checkCallbackAvailable",
            "Error checking callback availability",
            "error"
          );
          reject(e as Error);
        });
    });
    this.fetchMap.set("availability", {
      promise,
      time: new Date()
    });
    await promise;
  };

  checkCallbackAvailable = async (): Promise<boolean> => {
    await this.fetchAvailability();
    return this.state.callbackAvailable ?? false;
  };

  checkOnboardingCallCalendlyUrl = async (): Promise<string | undefined> => {
    await this.fetchAvailability();
    return this.state.scheduleOnboardingCallUrl;
  };
}
