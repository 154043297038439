import type { FC } from "react";
import React from "react";
import { useBloc } from "src/state/state";
import UserCubit, {
  ProfileProgramMembership
} from "src/state/UserCubit/UserCubit";

const DebugActiveProgram: FC = () => {
  const [, { programMemberships, setProgramMemberships }] = useBloc(UserCubit);

  const activeProgram = programMemberships?.find(
    (program) => program.active || (!program.start && !program.end)
  );

  const setActiveProgram = (program: ProfileProgramMembership) => {
    setProgramMemberships(program);
  };

  return (
    <div>
      <ul>
        {Object.values(ProfileProgramMembership).map((program) => {
          return (
            <li key={program}>
              {program}:{" "}
              <button onClick={() => setActiveProgram(program)}>
                {String(program === activeProgram?.program)}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DebugActiveProgram;
