import type { Color, ToastButton } from "@ionic/core";
import type { UseIonToastResult } from "@ionic/react";
import { Cubit } from "blac";
import translate from "src/lib/translate";
import type { TranslationKey } from "src/types/translationKey";

type ToastState = {
  linked: boolean;
};

export default class ToastBloc extends Cubit<ToastState> {
  ionicToast?: UseIonToastResult;

  constructor() {
    super({
      linked: false
    });
  }

  linkToast = (ionicToast: UseIonToastResult | null) => {
    if (this.ionicToast !== undefined || ionicToast === null) {
      return;
    }

    this.ionicToast = ionicToast;
    this.emit({ ...this.state, linked: true });
  };

  show = (
    message: TranslationKey,
    options: {
      severity?: Color;
      duration?: number;
      translationVariables?: Record<string, unknown>;
      buttons?: (ToastButton | string)[];
      onDidDismiss?: () => void;
    } = {}
  ) => {
    if (!this.ionicToast) {
      return;
    }
    const { severity, duration = 7000 } = options;

    const position = window.innerWidth < 768 ? "bottom" : "top";

    const msg = translate(message, options.translationVariables);

    const [present] = this.ionicToast;
    void present({
      message: msg,
      duration,
      color: severity,
      position,
      buttons: options.buttons,
      positionAnchor: "bottom",
      onDidDismiss: options.onDidDismiss,
      keyboardClose: true,
      swipeGesture: "vertical"
    });
  };

  error = (
    message: TranslationKey,
    translationVariables?: Record<string, unknown>
  ) => {
    this.show(message, { severity: "danger", translationVariables });
  };

  success = (message: TranslationKey) => {
    this.show(message, { severity: "success" });
  };
}
