import { useBlocNext } from "@blac/react";
import type { FC } from "react";
import React, { useEffect } from "react";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import Translate from "src/ui/components/Translate/Translate";

const GenericCreatingProgram: FC<{ lsKey: string }> = ({ lsKey }) => {
  const [, { nextStep }] = useBlocNext(SignupCustomBloc);
  const time = 3500;

  useEffect(() => {
    setTimeout(() => {
      StorageController.setItem(lsKey, "true");
      void nextStep();
    }, time + 200);
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <nine-progress
        loadTime={3500}
        img="https://cdn.sanity.io/images/g38rxyoc/production/76a7c3281e83c88cb8a42ad38bf19da3ed58ae4d-981x822.png?w=440"
        style={{ margin: "0 auto" }}
      >
        <div slot="info">
          <p className="as-subhead2 color-c-80">
            <Translate msg="processingInformation" />
          </p>
        </div>
      </nine-progress>
    </div>
  );
};
export default GenericCreatingProgram;
