import type {
  AnsweredQuestionnaire,
  ConsentStatusUpdateRequest,
  MedicationsResponse,
  UserProfileResponse
} from "@9amhealth/openapi";
import {
  ConsentControllerService,
  ConsentStatusResponse,
  DemoControllerService,
  MedicationsResponseItem,
  ProfileControllerService,
  QuestionnaireControllerService,
  SmsControllerService,
  UserProfileControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import type { Condition } from "src/constants/conditions";
import { globalEvents } from "src/constants/globalEvents";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import checkMedicalDataCollected from "src/lib/checkMedicalDataCollected";
import envVariables from "src/lib/envVariables";
import { extractErrorCode } from "src/lib/errors";
import { featureFlags } from "src/lib/featureFlags";
import formatPhoneNumber from "src/lib/formatPhoneNumber";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import type { UserData, UserState } from "src/state/UserCubit/UserState";
import {
  apiMiddleware,
  authenticationState,
  biometricAuthState,
  healthSyncState,
  loadingState,
  programState,
  pushNotificationState,
  subscriptionState,
  tracker,
  userPreferences,
  userState
} from "src/state/state";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { StorageController } from "../StorageBloc/StorageBloc";

export enum ProfileProgramMembership {
  MEDICARE = "MEDICARE",
  CDCDPP = "CDCDPP",
  ALLINONE_HEALTHCARE_CONCIERGE = "ALLINONE_HEALTHCARE_CONCIERGE",
  HEALTHY_WEIGHT_JOURNEY = "HEALTHY_WEIGHT_JOURNEY",
  DIABETES_AND_HEART_DISEASE_PREVENTION = "DIABETES_AND_HEART_DISEASE_PREVENTION"
}

export type ProfileAttributeSelectedGoals =
  | "Get expert medical advice and treatment"
  | "Reduce or prevent adding medications"
  | "Improve my nutrition and create good habits"
  | "Lose weight and optimize my health"
  | "Sleep better and increase my daily energy";

export enum ProfileAttributesKeys {
  programMemberships = "program.memberships",
  programSelectedGoals = "program.user_selected_goals",
  partnerEmployersCurrent = "partner.employers.current",
  partnerPayersCurrent = "partner.payers.current",
  medicalConditions = "medical.conditions",
  hasCompletedInitialOrFollowup = "consultation.has-completed-initial-or-followup"
}

export interface MedicalCondition {
  name: string;
  condition: {
    name: Condition;
    icd10: string;
  };
  abatement: string | null;
  onset?: string;
  isActive?: boolean;
}

export enum PayerTrait {
  ELIGIBLE_FOR_MEDICAL_BILLING = "ELIGIBLE_FOR_MEDICAL_BILLING",
  SUPPORTS_THIRD_PARTY_LAB_BILLING = "SUPPORTS_THIRD_PARTY_LAB_BILLING",
  REQUIRES_INITIAL_SYNC_VISIT = "REQUIRES_INITIAL_SYNC_VISIT"
}

interface Payer {
  active: boolean;
  start: string;
  end: string;
  partner: {
    id?: string;
    name: string;
    servedEmployerIds: string[];
    traits: PayerTrait[];
  };
  partnerId: string;
}

interface Employer {
  active: boolean;
  partner: {
    id: string;
    name: string;
    traits: [];
  };
  partnerId: string;
  start: string;
  end: string;
}

interface Membership {
  active: boolean;
  start: string;
  end: string;
  program?: ProfileProgramMembership;
}

export interface ProfileAttributes {
  [ProfileAttributesKeys.partnerEmployersCurrent]: Employer;
  [ProfileAttributesKeys.partnerPayersCurrent]: Payer;
  [ProfileAttributesKeys.programMemberships]: Membership[];
  [ProfileAttributesKeys.medicalConditions]: MedicalCondition[];
  [ProfileAttributesKeys.programSelectedGoals]: {
    name: ProfileAttributeSelectedGoals;
    isPrimary: boolean;
  }[];
  [ProfileAttributesKeys.hasCompletedInitialOrFollowup]: boolean;
}

export default class UserCubit extends Cubit<UserState> {
  private readonly loadingState = loadingState;
  completedQuestionnairesMemoryCache = new Set<string>();
  isTempUser = false;

  constructor(initialState?: UserState, init?: boolean) {
    super(initialState ?? {});

    if (init !== false) {
      void this.init();
    }
    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({});
    });
  }

  reset = () => {
    this.emitAndCache({});
  };

  cacheKey = "user-state";
  emitAndCache = (state: UserState): void => {
    this.emit(state);
    StorageController.setItem(this.cacheKey, JSON.stringify(state));
  };

  hasAppAccess = (): boolean => {
    const state = authenticationState.state.authenticationStatus;
    const { userData } = this.state;
    if (
      state === "authenticated" &&
      userData &&
      (userData.email === undefined || userData.passwordSet === false)
    ) {
      return false;
    }

    // catch unauthenticated users
    if (state !== "authenticated") {
      return false;
    }

    return true;
  };

  populateUserprofileWithDemoData = async () => {
    // only allow on dev and qa backend
    const backendApiUrl = envVariables.API_BASE_URL;
    if (!backendApiUrl.includes(".dev.") && !backendApiUrl.includes(".qa.")) {
      return;
    }

    try {
      await DemoControllerService.populate();
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  private async init(): Promise<void> {
    const cachedValue =
      StorageController.activeUserId &&
      StorageController.getItem(this.cacheKey);
    if (cachedValue) {
      this.emit(JSON.parse(cachedValue) as UserState);
    }
    const url = new URL(window.location.href);
    const temp = url.searchParams.get("temp") !== null;
    if (temp) {
      this.isTempUser = true;
    }
  }

  public get isUserRegistered(): boolean {
    return Boolean(this.state.userData?.email);
  }

  public get programMemberships() {
    return this.state.profileAttributes?.[
      ProfileAttributesKeys.programMemberships
    ];
  }

  public get medicalConditions() {
    return this.state.profileAttributes?.[
      ProfileAttributesKeys.medicalConditions
    ];
  }

  public get partnerPayer() {
    return this.state.profileAttributes?.[
      ProfileAttributesKeys.partnerPayersCurrent
    ];
  }

  public readonly hasProgramMembership = (
    program: ProfileProgramMembership
  ): boolean => {
    return (
      this.programMemberships?.find(
        (p) => p.program === program && p.active
      ) !== undefined
    );
  };

  public readonly setUserInfo = async (): Promise<void> => {
    if (this.isTempUser) return;

    const userData = await this.loadUserInfo();

    if (userData) {
      tracker.setAlias(userData.analyticsToken);
      this.emitAndCache({ ...this.state, userData });
      void this.loadMedications();
    }
  };

  fetchUserProfile = async (): Promise<UserProfileResponse> =>
    apiMiddleware.cached(
      async () => (await UserProfileControllerService.getUserProfile()).data
    );

  public readonly loadUserInfo = async (): Promise<UserData | undefined> => {
    try {
      const data = await this.fetchUserProfile();

      const userData = {
        email: data.profile.email as string | undefined,
        analyticsToken: data.profile.analyticsToken as string,
        id: data.id,
        emailVerified: data.profile.emailVerified as boolean,
        passwordSet: Boolean(data.profile.passwordSet),
        mfaActive: Boolean(data.profile.mfaActive)
      } satisfies UserData;

      this.emitAndCache({
        ...this.state,
        userData
      });

      return userData;
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    return undefined;
  };

  private readonly loadMedications = async (): Promise<
    MedicationsResponse | undefined
  > => {
    if (this.isTempUser) return;

    let result: MedicationsResponse | undefined = undefined;
    loadingState.start(LoadingKey.medications);
    try {
      const response = await ProfileControllerService.getMedications();
      result = response.data;
      this.emitAndCache({ ...this.state, medications: result });
    } catch (e: unknown) {
      reportErrorSentry(e);
    } finally {
      loadingState.finish(LoadingKey.medications);
    }

    return result;
  };

  private fetchAnsweredQuestionnairesPromise:
    | [number, Promise<AnsweredQuestionnaire[]>]
    | [number, undefined] = [0, undefined];

  clearAnsweredQuestionnairesCache = () => {
    this.fetchAnsweredQuestionnairesPromise = [0, undefined];
  };

  fetchAnsweredQuestionnaires = async (): Promise<AnsweredQuestionnaire[]> => {
    let [cachedAt, promise] = this.fetchAnsweredQuestionnairesPromise;
    const now = Date.now();
    const cacheTime = 1000 * 60 * 1; // 1 minutes
    const cacheValid = now - cachedAt < cacheTime;

    if (!promise || !cacheValid) {
      promise = new Promise((resolve, reject) => {
        QuestionnaireControllerService.getAnsweredQuestionnaires()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            reject(e as Error);
          });
      });

      cachedAt = now;
      this.fetchAnsweredQuestionnairesPromise = [cachedAt, promise];
    }

    try {
      return await promise;
    } catch (e: unknown) {
      reportErrorSentry(e);
      return [];
    }
  };

  public readonly loadUserAnsweredQuestionnaires = async (): Promise<
    AnsweredQuestionnaire[] | undefined
  > => {
    if (this.isTempUser) return;

    this.loadingState.start(LoadingKey.answeredQuestionnaires);

    try {
      const answeredQuestionnaires = await this.fetchAnsweredQuestionnaires();

      addSentryBreadcrumb(
        "auth",
        `Successfully load user answered questionnaires`
      );

      this.emitAndCache({ ...this.state, answeredQuestionnaires });
    } catch (e: unknown) {
      reportErrorSentry(e);
    } finally {
      this.loadingState.finish(LoadingKey.answeredQuestionnaires);
    }

    return this.state.answeredQuestionnaires;
  };

  public readonly checkUserHasCompletedQuestionnaire__MEMORY = async (
    questionnaireId: string
  ): Promise<boolean> => {
    try {
      const sessItems = sessionStorage.getItem("completedQuestionnaires");
      if (sessItems) {
        const parsed = JSON.parse(sessItems) ?? [];
        if (Array.isArray(parsed)) {
          this.completedQuestionnairesMemoryCache = new Set(
            parsed
          ) as Set<string>;
        }
      }

      return this.completedQuestionnairesMemoryCache.has(questionnaireId);
    } catch (error) {
      reportErrorSentry(error);
    }
    return false;
  };

  public readonly checkUserHasAnsweredQuestionnaire = async (
    questionnaireId: string,
    filter: {
      questionId?: string;
    } = {},
    loadAll?: boolean
  ): Promise<boolean> => {
    if (loadAll !== false) {
      await this.loadUserAnsweredQuestionnaires();
    }

    const allQuestionnaires = this.state.answeredQuestionnaires ?? [];
    const thisQuestionnaire = allQuestionnaires.find(
      (q) => q.questionnaireRef.id === questionnaireId
    );

    if (thisQuestionnaire && filter.questionId) {
      const answers = (thisQuestionnaire.answers.json ?? []) as {
        questionId: string;
      }[];
      const thisQuestion = answers.find(
        (a) => a.questionId === filter.questionId
      );
      return Boolean(thisQuestion);
    }

    return Boolean(thisQuestionnaire);
  };

  public readonly checkMedicalDataCollected = async (): Promise<boolean> => {
    await this.loadUserAnsweredQuestionnaires();
    const answeredQuestionnaires = this.state.answeredQuestionnaires ?? [];
    return checkMedicalDataCollected(undefined, answeredQuestionnaires);
  };

  readonly loadRequiredAppUserData = async (): Promise<void> => {
    loadingState.start(LoadingKey.requiredAppUserData);
    const showLoading =
      !this.state.userData?.email && !biometricAuthState.state.showSetupPrompt;

    // only start loading overlay, if we dont have any user data yet, and we dont have biometric auth setup
    if (showLoading) {
      BlockingLoadingOverlayController.startLoading({
        bg: "branded"
      });
    }

    let loadFullDataSet = true;
    if (this.isTempUser) {
      loadFullDataSet = false;
    }

    try {
      const [userData, profileAttributes] = await Promise.all([
        this.loadUserInfo(),
        userState.getProfileAttributes(),
        ...(loadFullDataSet
          ? [
              subscriptionState.loadAllSubscriptions(),
              featureFlags.loadBackendFlags(),
              this.loadUserAnsweredQuestionnaires(),
              userPreferences.setupUserProperties(),
              programState.checkUserProfileProgramData(),
              this.getConsentStatus()
            ]
          : [])
      ]);

      if (StorageController.getItem("healthActive") === "true") {
        void healthSyncState.checkAndSync();
      }

      let setNewState = false;
      const newState: typeof this.state = {
        ...this.state
      };

      if (userData) {
        newState.userData = userData;
        setNewState = true;
        tracker.identifyUserByTrackingId(userData.analyticsToken);
        void pushNotificationState.init();
      }

      if (profileAttributes) {
        newState.profileAttributes = profileAttributes;
        setNewState = true;
      }

      if (setNewState) {
        this.emitAndCache(newState);
      }
    } catch (e: unknown) {
      reportErrorSentry(e);
    } finally {
      loadingState.finish(LoadingKey.requiredAppUserData);
      if (showLoading) {
        BlockingLoadingOverlayController.endLoading();
      }
    }
  };

  static setNotificationNumber = async (
    phoneNumber: string,
    options: {
      showLoading?: boolean;
    } = {}
  ): Promise<{ error: string }> => {
    apiMiddleware.clearAll();
    let error = "";
    try {
      if (options.showLoading !== false)
        loadingState.start(LoadingKey.treatmentPlan);
      const number = formatPhoneNumber(phoneNumber);

      if (number)
        await SmsControllerService.registerPhoneNumber({
          phoneNumber: number,
          verify: false
        });
      addSentryBreadcrumb("auth", `Successfully set notification number`);
    } catch (e: unknown) {
      reportErrorSentry(e);
      error = extractErrorCode(e);
    } finally {
      if (options.showLoading !== false)
        loadingState.finish(LoadingKey.treatmentPlan);
    }
    return { error };
  };

  private getUserMedication(
    medication: MedicationsResponseItem.medication
  ): MedicationsResponseItem | undefined {
    const medicationList = this.state.medications?.medications ?? [];
    return medicationList.find((item) => item.medication === medication);
  }

  get userIsOnInsulin(): boolean {
    const insulin = this.getUserMedication(
      MedicationsResponseItem.medication.INSULIN
    );
    return Boolean(insulin);
  }

  profileAttributes?: ProfileAttributes;
  getProfileAttributes = async (
    attributes: ProfileAttributesKeys[] = [
      ProfileAttributesKeys.partnerEmployersCurrent,
      ProfileAttributesKeys.partnerPayersCurrent,
      ProfileAttributesKeys.programMemberships,
      ProfileAttributesKeys.medicalConditions,
      ProfileAttributesKeys.programSelectedGoals,
      ProfileAttributesKeys.hasCompletedInitialOrFollowup
    ]
  ): Promise<ProfileAttributes | undefined> => {
    return new Promise<ProfileAttributes | undefined>((resolve) => {
      ProfileControllerService.getMyProfileAttributeValues(attributes)
        .then((res) => {
          const data = res.data as ProfileAttributes;
          this.profileAttributes = data;
          resolve(data);
        })
        .catch((e: unknown) => {
          reportErrorSentry(e);
        });
    });
  };

  checkProfileAttributesCurrentProgramMembership = async (): Promise<
    ProfileProgramMembership[] | false
  > => {
    const data =
      this.state.profileAttributes?.[ProfileAttributesKeys.programMemberships];

    // check if data exists
    if (typeof data === "undefined" || data.length === 0) {
      return false;
    }

    //   collect programs
    const programs: ProfileProgramMembership[] = [];
    data.forEach((item) => {
      if (item.active && item.program) {
        programs.push(item.program);
      }
    });

    return programs;
  };

  public readonly setProgramMemberships = (
    program: ProfileProgramMembership
  ) => {
    if (this.state.profileAttributes) {
      this.emit({
        ...this.state,
        profileAttributes: {
          ...this.state.profileAttributes,
          [ProfileAttributesKeys.programMemberships]: [
            {
              active: true,
              start: "",
              end: "",
              program
            }
          ]
        }
      });
    }
  };

  /******************** */
  // Consent Management //
  /******************** */

  shouldIgnoreConsentUpdate = (consent: ConsentStatusResponse) => {
    const ignoreConsentTypes = [
      ConsentStatusResponse.consent.HEALTH_INFORMATION_RELEASE
    ];
    const ignoreConsentVersions: Partial<
      Record<ConsentStatusResponse.consent, number>
    > = {
      [ConsentStatusResponse.consent.TERMS_OF_SERVICE]: 20240903
    };

    const ignoreConsent = ignoreConsentTypes.includes(consent.consent);
    const ignoreConsentVersion =
      ignoreConsentVersions[consent.consent] === consent.version;

    return ignoreConsent || ignoreConsentVersion;
  };

  getConsentStatus = async (): Promise<void> => {
    try {
      const result = await ConsentControllerService.listConsentStatuses();
      const consentStatus = result.data;
      this.emitAndCache({
        ...this.state,
        consentStatus
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  acceptConsents = async (): Promise<void> => {
    if (
      this.state.consentStatus === undefined ||
      this.state.consentStatus.length === 0
    ) {
      await this.getConsentStatus();
    }

    const newStatuses = this.state.consentStatus?.map((c) => ({
      ...c,
      status: ConsentStatusResponse.status.ACCEPTED
    }));

    try {
      await ConsentControllerService.updateConsentStatus(
        newStatuses as ConsentStatusUpdateRequest[]
      );
      this.emitAndCache({
        ...this.state,
        consentStatus: newStatuses
      });
    } catch (error: unknown) {
      reportErrorSentry(error);
    }
  };

  get hasConsentsNotAccepted(): boolean | undefined {
    if (!this.state.consentStatus) {
      return undefined;
    }

    const consents = this.state.consentStatus.filter(
      (con) => !this.shouldIgnoreConsentUpdate(con)
    );

    if (consents.length === 0) {
      // if no consents, then we assume they are not accepted
      return true;
    }

    const justStatus = consents.map((c) => c.status);
    const allAccepted = justStatus.every(
      (s) => s === ConsentStatusResponse.status.ACCEPTED
    );

    return !allAccepted;
  }
}
