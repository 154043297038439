import React, { FC } from "react";

export const EyeIcon: FC = () => (
  <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.771 15.725a3.69 3.69 0 0 0 2.725-1.137c.75-.759 1.125-1.663 1.125-2.713a3.69 3.69 0 0 0-1.137-2.725c-.759-.75-1.663-1.125-2.713-1.125-1.067 0-1.975.38-2.725 1.137-.75.759-1.125 1.663-1.125 2.713A3.69 3.69 0 0 0 10.06 14.6c.758.75 1.662 1.125 2.712 1.125Zm0-1.075c-.767 0-1.42-.275-1.962-.825-.542-.55-.813-1.2-.813-1.95 0-.767.275-1.42.825-1.963.55-.541 1.2-.812 1.95-.812.767 0 1.421.275 1.963.825.541.55.812 1.2.812 1.95 0 .767-.275 1.42-.825 1.963-.55.541-1.2.812-1.95.812Zm0 4.1c-2.25 0-4.308-.625-6.175-1.875s-3.267-2.917-4.2-5c.933-2.083 2.333-3.75 4.2-5A10.863 10.863 0 0 1 12.771 5c2.25 0 4.308.625 6.175 1.875s3.267 2.917 4.2 5c-.933 2.083-2.333 3.75-4.2 5a10.863 10.863 0 0 1-6.175 1.875Zm0-1.1c1.983 0 3.792-.517 5.425-1.55s2.892-2.442 3.775-4.225c-.883-1.783-2.142-3.192-3.775-4.225-1.633-1.033-3.442-1.55-5.425-1.55-1.983 0-3.796.517-5.437 1.55-1.642 1.033-2.896 2.442-3.763 4.225.867 1.783 2.121 3.192 3.763 4.225 1.641 1.033 3.454 1.55 5.437 1.55Z"
      fill="#212121"
    />
  </svg>
);
