import {
  FileControllerService,
  UserShortLivedTokenControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import { nanoid } from "nanoid";
import appErrors from "src/constants/appErrors";
import type FileType from "src/constants/fileType";
import { globalEvents } from "src/constants/globalEvents";
import { OpenBrowser } from "src/hybrid/components/Browser";
import { DateFormats, dateLocal } from "src/lib/date";
import envVariables from "src/lib/envVariables";
import imageLoadPromise from "src/lib/imageLoadPromise";
import mimeType from "src/lib/mimeType";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { authenticationState, toast } from "src/state/state";

interface FileAttributes {
  "file.name"?: string;
  "file.%s.name"?: string;
  "image.width"?: number;
  "image.height"?: number;
  "subscription.identity.face"?: boolean;
  "subscription.identity.id"?: boolean;
  "labs.user_result"?: boolean;
  "file.tags"?: string[];
  "file.lastModified"?: string;
  "file.size"?: number;
  "file.type"?: FileType;
  "source.name"?: string;
}

export interface FileItem {
  id: string;
  attributes: FileAttributes;
  type: string;
  blob?: Blob;
  path?: string;
  size?: number;
  error?: string;
  local?: boolean;
}

export default class FilesCubit extends Cubit<FileItem[]> {
  private onAddCallbacks: Record<
    string,
    ((file: FileItem) => void) | undefined
  > = {};

  constructor() {
    super([]);

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit([]);
    });
  }

  public readonly getFileById = (id: string): FileItem | undefined => {
    return this.state.find((item) => item.id === id);
  };

  public readonly waitForFile = async (id: string): Promise<FileItem> => {
    return new Promise((resolve) => {
      const index = nanoid(5);
      this.onAddCallbacks[index] = (file): void => {
        if (file.id === id) {
          resolve(file);
          this.onAddCallbacks[index] = undefined;
        }
      };
    });
  };

  public readonly getFileByAttribute = (
    attribute: FileAttributes
  ): FileItem[] => {
    return this.state.filter((item) => {
      const attributeName = Object.keys(attribute)[0] as never;
      return (
        Object.keys(item.attributes).includes(attributeName) &&
        item.attributes[attributeName] === attribute[attributeName]
      );
    });
  };

  public readonly uploadFile = async (options: {
    file: File;
    fileAttributes?: FileAttributes;
  }): Promise<FileItem | undefined> => {
    try {
      const { file, fileAttributes } = options;

      const attributes: FileAttributes = {
        "source.name": envVariables.APP_ENV
      };
      attributes["file.name"] = file.name;
      attributes["file.lastModified"] = dateLocal(file.lastModified).format(
        DateFormats.ISO_FULL
      );
      attributes["file.size"] = file.size;
      const filePath = URL.createObjectURL(file);

      if (mimeType.isImage(file.type)) {
        const img = await imageLoadPromise(filePath);

        attributes["image.width"] = img.width;
        attributes["image.height"] = img.height;
      }

      const allAttributes = {
        ...attributes,
        ...fileAttributes
      };

      const response = await FileControllerService.createFile(file);

      const item: FileItem = {
        id: response.data.fileId,
        attributes: allAttributes,
        local: true,
        type: file.type,
        path: filePath
      };

      this.emit([...this.state, item]);
      return item;
    } catch (e: unknown) {
      toast.error("error_upload_file_failed");
      reportErrorSentry(e);
    }
  };

  static requestDownloadFile = async (id: string): Promise<Response> =>
    fetch(`${envVariables.API_BASE_URL}/v1/files/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticationState.accessToken}`
      }
    });

  static requestDownloadFilePublicUrl = async (id: string): Promise<string> => {
    const shortLived =
      await UserShortLivedTokenControllerService.retrieveShortLivedToken();
    const { accessToken } = shortLived.data;
    return `${envVariables.API_BASE_URL}/v1/files/${id}?authToken=${accessToken}`;
  };

  static requestDownloadFilePreview = async (
    id: string,
    w: number,
    h: number
  ): Promise<Response> =>
    fetch(`${envVariables.API_BASE_URL}/v1/files/${id}/preview/${w}/${h}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticationState.accessToken}`
      }
    });

  public readonly addFile = (id: string, fileBlob: Blob): FileItem => {
    const file: FileItem = {
      id,
      attributes: {},
      type: ""
    };

    try {
      file.path = URL.createObjectURL(fileBlob);
      this.emit([...this.state, file]);
      this.addNotify(file);
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.error(e);
      return { ...file, error: appErrors.generic };
    }

    return file;
  };

  /**
   * Requests a short lived token from the server and then starts a download of the file
   */
  static startFileDownload = async (id: string): Promise<void> => {
    const url = await FilesCubit.requestDownloadFilePublicUrl(id);
    const name = `9amHealth_${id}`;

    const link = document.createElement("a");
    link.download = name;
    link.href = url;

    void OpenBrowser(url, {
      useBaseUrl: false,
      presentationStyle: "popover"
    });
  };

  public readonly removeFile = (id: string): void => {
    this.emit(this.state.filter((file) => id !== file.id));
  };

  private readonly addNotify = (file: FileItem): void => {
    for (const id in this.onAddCallbacks) {
      this.onAddCallbacks[id]?.(file);
    }
  };

  previewUrlCache: Record<string, string | undefined> = {};
  previewRequestCache: Record<string, Promise<string> | undefined> = {};
  public readonly loadPreview = async (
    id: string,
    w = 400,
    h = 400
  ): Promise<string> => {
    const requestId = `${id}-${w}-${h}`;

    const request = async (): Promise<string> => {
      try {
        const response = await FilesCubit.requestDownloadFilePreview(id, w, h);
        if (!response.ok) {
          throw new Error(`Failed to load preview for file ${id}`);
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        this.previewUrlCache[requestId] = url;
        return url;
      } catch (e: unknown) {
        reportErrorSentry(e);
      }
      return "";
    };

    if (this.previewUrlCache[requestId]) {
      return this.previewUrlCache[requestId] ?? "";
    }

    if (this.previewRequestCache[requestId]) {
      return this.previewRequestCache[requestId] ?? "";
    }

    this.previewRequestCache[requestId] = request();
    return this.previewRequestCache[requestId] ?? "";
  };
}
