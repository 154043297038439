import {
  GetSuggestedSubscriptionStepResponse,
  IneligibilityReason
} from "@9amhealth/openapi";

export enum SmsNotificationsPermissionStatus {
  SUCCESS = "success",
  FAILURE = "failure"
}

export interface TreatmentPlanState {
  step?: GetSuggestedSubscriptionStepResponse;
  nextStep?: GetSuggestedSubscriptionStepResponse;
  smsNotificationsPermissionStatus?: SmsNotificationsPermissionStatus;
  dataNeededReasons: string[];
  ineligibilityReasons: IneligibilityReason[];
}

export enum ItemType {
  SUBSCRIPTION_PLAN_SUGGESTION = "SubscriptionPlanSuggestion",
  LAB_TEST_SUGGESTION = "LabTestSuggestion",
  MEDICATION_SUGGESTION = "MedicationSuggestion",
  PRESCRIBED_MEDICATION = "PrescribedMedication",
  PRELIMINARY_MEDICATION_SUGGESTION = "PreliminaryMedicationSuggestion",
  OVER_THE_COUNTER_GLUCOMETER = "OverTheCounterGlucometer",
  OVER_THE_COUNTER_LAB_TEST = "OverTheCounterLabTest",
  OVER_THE_COUNTER_MICROLET_COLOR_LANCET_ITEM = "OverTheCounterMicroletColorLancetItem",
  OVER_THE_COUNTER_STRIP_ITEM = "OverTheCounterStripItem",
  prescription = "PrescriptionData",
  PRESCRIBED_SUPPLY = "PrescribedSupply"
}

export const MedicationItemTypes: ItemType[] = [
  ItemType.MEDICATION_SUGGESTION,
  ItemType.PRELIMINARY_MEDICATION_SUGGESTION
];

export enum SubscriptionInterval {
  oneTime = "P0D",
  monthly = "P1M",
  quarterly = "P3M"
}
