import React, { FC } from "react";

export const EyeClosedIcon: FC = () => (
  <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m16.296 12.925-.825-.85c.25-1.1-.02-1.987-.812-2.662-.792-.675-1.63-.896-2.513-.663l-.85-.825c.167-.083.384-.158.65-.225.267-.067.542-.1.825-.1 1.067 0 1.975.375 2.725 1.125s1.125 1.658 1.125 2.725c0 .283-.029.558-.087.825a2.17 2.17 0 0 1-.238.65Zm3.225 3.175-.775-.75a12.372 12.372 0 0 0 1.913-1.813 8.328 8.328 0 0 0 1.312-2.087c-.85-1.783-2.091-3.192-3.725-4.225a9.855 9.855 0 0 0-5.375-1.55c-.566 0-1.154.05-1.762.15A8.84 8.84 0 0 0 9.57 6.2l-.85-.875c.55-.217 1.196-.396 1.938-.538.741-.141 1.47-.212 2.187-.212 2.2 0 4.246.62 6.138 1.862 1.891 1.242 3.279 2.913 4.162 5.013a10.65 10.65 0 0 1-1.487 2.525A13.223 13.223 0 0 1 19.52 16.1Zm1.35 5.45-4.05-4.025c-.433.217-1.008.404-1.725.563-.716.158-1.492.237-2.325.237-2.25 0-4.317-.62-6.2-1.863-1.883-1.241-3.275-2.912-4.175-5.012.367-.9.875-1.75 1.525-2.55.65-.8 1.375-1.517 2.175-2.15L3.071 3.775 3.846 3l17.75 17.75-.725.8Zm-14-13.975c-.6.433-1.225 1.004-1.875 1.712-.65.709-1.125 1.43-1.425 2.163.85 1.783 2.1 3.192 3.75 4.225 1.65 1.033 3.492 1.55 5.525 1.55.633 0 1.263-.058 1.888-.175.625-.117 1.046-.233 1.262-.35l-1.7-1.725a5.859 5.859 0 0 1-.687.213c-.292.074-.571.112-.838.112-1.067 0-1.975-.37-2.725-1.113-.75-.741-1.125-1.654-1.125-2.737 0-.233.038-.496.113-.787.075-.292.145-.538.212-.738l-2.375-2.35Z"
      fill="#212121"
    />
  </svg>
);
