import { CSSProperties, FC, ReactNode, SyntheticEvent } from "react";
import React from "react";
import { Link } from "react-router-dom";
import {
  CheckmarkIcon,
  IconCaretToRightSmallArrow,
  IconCrossXCloseMissed
} from "src/constants/icons";
import styled from "@emotion/styled";
import type { LabColor } from "src/constants/LabColor";
import clsx from "clsx";

const StyledLink = styled(Link)`
  border-radius: inherit;
  transition: background-color 0.1s ease-in-out;
  color: inherit;
  text-decoration: inherit;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .image {
    position: relative;
    border-radius: 8px;
    background-color: #f2efe7;
    overflow: hidden;
    height: 54px;
    width: 54px;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon {
    position: relative;
  }

  .badge {
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    position: absolute;
    border: 2px solid white;
    right: -0.425rem;
    top: -0.425rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: var(--greys-grey, #aaa7a0);

    &.completed {
      background: #6fc696;
    }

    div {
      width: 100%;
      height: 100%;

      svg {
        scale: 0.8;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  &[aria-disabled="true"] {
    pointer-events: none;

    .icon,
    .label,
    .arrow {
      opacity: var(--disabled-opacity, 0.2);
    }
  }

  body &:active {
    background-color: #0000000a;
  }

  body &:hover {
    text-decoration: none;
  }

  .custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;

    svg {
      transform: scale(1.4);
    }
  }

  //  all items in the StyleLink are styled in "Collection.ts"
`;

const Badge: FC<{ type?: "completed" | "skipped" }> = ({ type }) => {
  if (!type) return null;

  const completed = type === "completed";

  return (
    <div
      className={clsx({
        badge: true,
        completed
      })}
    >
      <div>{completed ? <CheckmarkIcon /> : <IconCrossXCloseMissed />}</div>
    </div>
  );
};

const CollectionItemLink: FC<{
  icon?: ReactNode;
  imageSrc?: string;
  imageAlt?: string;
  label?: ReactNode;
  card?: boolean;
  to?: string;
  onClick?: () => void;
  iconSize?: string;
  disabled?: boolean;
  action?: ReactNode;
  status?: ReactNode;
  detail?: ReactNode;
  color?: LabColor | string;
  size?: "large";
  endIcon?: ReactNode;
  badgeType?: "completed" | "skipped";
  disabledOpacity?: number;
}> = (props) => {
  const {
    icon,
    label,
    endIcon,
    to,
    iconSize,
    onClick,
    disabled,
    action,
    disabledOpacity = 0.2
  } = props;
  const handleClick = (e: SyntheticEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <StyledLink
      to={to ?? "#"}
      onClick={handleClick}
      className="link"
      aria-disabled={disabled ? "true" : "false"}
      style={
        {
          "--icon-size": iconSize,
          "--icon-bg": props.color,
          "--disabled-opacity": disabledOpacity
        } as CSSProperties
      }
    >
      {icon && (
        <div className="icon" data-size={props.size}>
          {icon}

          <Badge type={props.badgeType} />
        </div>
      )}
      {props.imageSrc && (
        <div className="image" data-size={props.size}>
          <img src={props.imageSrc} alt={props.imageAlt} />

          <Badge type={props.badgeType} />
        </div>
      )}
      <div className="label">
        <div className={props.card ? "as-body2 m0" : ""}>{label}</div>
        {props.status && (
          <div className={props.card ? "as-little color-c-80 m0" : "status"}>
            {props.status}
          </div>
        )}
      </div>
      {props.detail && <div className="detail">{props.detail}</div>}
      {(to || onClick) && !action && (
        <div className={endIcon ? "custom" : "arrow"}>
          {endIcon ?? <IconCaretToRightSmallArrow />}
        </div>
      )}
      {action && <div className="action">{action}</div>}
    </StyledLink>
  );
};

export default CollectionItemLink;
