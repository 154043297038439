import { useBlocNext } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import {
  IconArrows,
  IconLabData,
  IconLocker,
  IconShield
} from "src/constants/icons";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import { userPreferences } from "src/state/state";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import Loader from "../Loader/Loader";
import Translate from "../Translate/Translate";
import SignupCustomBloc from "./state/SignupCustomBloc";

const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
`;

const Header = styled.h2`
  margin-top: 2px;
  margin-left: 4px;
`;

const EstablishCareStep: FC = () => {
  const [, { nextStep }] = useBlocNext(SignupCustomBloc);
  const [loading, setLoading] = React.useState(false);

  const handleContinue = React.useCallback(() => {
    setLoading(true);
    void userPreferences
      .updateUserPreferences({
        [UserPreferenceKeys.healthInformationExchangeCompleted]: true
      })
      .then(() => {
        void nextStep();
      });
    void nextStep();
  }, []);

  return (
    <nine-content
      style={{
        "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`,
        padding: "2rem 1.6rem"
      }}
    >
      <Loader active={loading} fullPage>
        <nine-spacer s="xl"></nine-spacer>
        <WrapperIcon>
          <IconLabData />
        </WrapperIcon>
        <nine-spacer s="md"></nine-spacer>

        <h1 className="as-h4-large" style={{ textAlign: "center" }}>
          <Translate msg="healthInfoExchange.simplifyHealthcare" />
        </h1>

        <nine-spacer s="md"></nine-spacer>
        <p className="m0 color-c-80" style={{ textAlign: "center" }}>
          <Translate msg="healthInfoExchange.description" />
        </p>

        <nine-spacer s="lg"></nine-spacer>
        <Title>
          <IconArrows />
          <Header className="as-body2">
            <Translate msg="healthInfoExchange.connectingProviders.title" />
          </Header>
        </Title>
        <p className="as-little">
          <Translate msg="healthInfoExchange.connectingProviders.description" />
        </p>

        <nine-spacer s="sm"></nine-spacer>
        <Title>
          <IconShield />
          <Header className="as-body2">
            <Translate msg="healthInfoExchange.reducingClinicalRisk.title" />
          </Header>
        </Title>
        <p className="as-little">
          <Translate msg="healthInfoExchange.reducingClinicalRisk.description" />
        </p>

        <nine-spacer s="sm"></nine-spacer>
        <Title>
          <IconLocker />
          <Header className="as-body2">
            <Translate msg="healthInfoExchange.limitedSecureAccess.title" />
          </Header>
        </Title>
        <p className="as-little">
          <Translate msg="healthInfoExchange.limitedSecureAccess.description" />
        </p>

        <nine-spacer s="xl"></nine-spacer>
        <nine-center style={{ minHeight: "unset" }}>
          <nine-button onClick={handleContinue}>
            <Translate msg="understood" />
          </nine-button>
        </nine-center>
        <nine-spacer s="xl"></nine-spacer>
      </Loader>
    </nine-content>
  );
  return <></>;
};

export default EstablishCareStep;
