import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import type { CSSProperties, FC } from "react";
import React, { useEffect, useState } from "react";
import reportErrorSentry from "src/lib/reportErrorSentry";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { toast, useBloc } from "src/state/state";
import Loader from "src/ui/components/Loader/Loader";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";

import {
  iconUrlCalendarDateSimple,
  iconUrlCareHandsHeart,
  iconUrlStar,
  iconUrlVideoPlay
} from "src/constants/iconUrls";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import { CDCDPP_PROGRAM_ITEM_SKU } from "src/constants/misc";
import translate from "src/lib/translate";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import { Skeleton } from "src/ui/components/AsyncContent/AsyncContent";
import type { CustomizedCheckoutScreenContentProps } from "src/ui/components/SignupCustomContent/state/CustomizedCheckoutScreenBloc";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import Translate from "src/ui/components/Translate/Translate";
import Highlights from "src/ui/styled/Highlights";
import { DynamicContentItemProps, makeFC } from "src/lib/useContentForProgram";
import { useBlocNext } from "@blac/react";

const defaultContent: CustomizedCheckoutScreenContentProps = {
  image: () => (
    <img
      src={
        "https://cdn.sanity.io/images/g38rxyoc/production/eb94039611519d30538d1a2a8b61dc0e196c70e0-1374x768.jpg?w=800&auto=format"
      }
      className="rounded-small aspect"
      style={{ "--img-aspect": "458/256" } as CSSProperties}
      alt={translate(`program.title_${ProfileProgramMembership.CDCDPP}`)}
    ></img>
  ),
  title: () => (
    <Translate msg={`program.title_${ProfileProgramMembership.CDCDPP}`} />
  ),
  subtitle: () => (
    <Translate msg={`program.description_${ProfileProgramMembership.CDCDPP}`} />
  ),
  highlights: () => (
    <Highlights>
      <ul>
        <li>
          <img src={iconUrlStar} />
          <Translate msg="nationalInitiative" />
        </li>
        <li>
          <img src={iconUrlVideoPlay} />
          <Translate msg="videoContent" />
        </li>
        <li>
          <img src={iconUrlCareHandsHeart} />
          <Translate msg="freeOfCharge" />
        </li>
        <li>
          <img src={iconUrlCalendarDateSimple} />
          {translate(`monthProgram`, {
            months: "12"
          })}
        </li>
      </ul>
    </Highlights>
  ),
  note: () => <>{<Translate msg="benefit.description.lifestyle" />}</>,
  list: () => (
    <ul>
      <li>
        <Translate msg="benefit.lifestyleCoaching" />
      </li>
      <li>
        <Translate msg="benefit.weightScale" />
      </li>
      <li>
        <Translate msg="benefit.oneToOneCoaching" />
      </li>
      <li>
        <Translate msg="benefit.cashUponCompletion" />
      </li>
    </ul>
  )
};

const EnrollButton: FC = () => {
  const [, { loadAllSubscriptions, filterAllSubscriptions, buySubscription }] =
    useBloc(SubscriptionCubit);
  const [loading, setLoading] = useState(false);
  const [, { nextStep }] = useBlocNext(SignupCustomBloc);

  useEffect(() => {
    void loadAllSubscriptions();
  }, []);

  const funnelSpecificSubscription = filterAllSubscriptions({
    status: [
      SubscriptionDetailsResponse.status.DRAFT,
      SubscriptionDetailsResponse.status.IN_REVIEW,
      SubscriptionDetailsResponse.status.ACTIVE
    ],
    items: [CDCDPP_PROGRAM_ITEM_SKU],
    looseCheck: true
  })[0] as SubscriptionDetailsResponse | undefined;

  if (!funnelSpecificSubscription) {
    reportErrorSentry(new Error("User does not have funnel specific item"));

    return (
      <ErrorBox data-severity="error">
        <Translate msg="error.creatingPlan" />
      </ErrorBox>
    );
  }

  return (
    <Loader active={loading} fullPage>
      <nine-button
        onClick={() => {
          if (loading) return;

          setLoading(true);
          void buySubscription(funnelSpecificSubscription.id, {
            onSuccess: () => void nextStep(),
            onError: () => {
              reportErrorSentry(new Error("Error buying subscription"));
              toast.error("error_generic");
            }
          });
        }}
      >
        {translate("enroll")} <span style={{ fontWeight: 400 }}>&nbsp;$0</span>
      </nine-button>
    </Loader>
  );
};

const CDCDPPCustomizedCheckoutScreen: FC = () => {
  const matchingContent = defaultContent;

  const content: CustomizedCheckoutScreenContentProps = {
    image:
      matchingContent.image ??
      makeFC(
        <Skeleton
          width={"100%"}
          className="no-transform"
          style={{ aspectRatio: "458/256" }}
        />
      ),
    title:
      matchingContent.title ??
      makeFC(<Skeleton width={"80%"} height={32} className="no-transform" />),
    subtitle:
      matchingContent.subtitle ??
      makeFC(<Skeleton width={"100%"} height={56} className="no-transform" />),
    highlights:
      matchingContent.highlights ??
      makeFC(<Skeleton width={"100%"} height={90} className="no-transform" />),
    note:
      matchingContent.note ??
      makeFC(<Skeleton width={"100%"} height={50} className="no-transform" />),
    list:
      matchingContent.list ??
      makeFC(<Skeleton className="no-transform" width={"100%"} height={350} />)
  };

  const itemProps: DynamicContentItemProps = {
    eligibleFoeMedicalBilling: false
  };

  return (
    <>
      <nine-info-section
        style={{
          margin: "0.8em 0",
          width: `min(${APP_CONTENT_WIDTH}px, 100vw)`
        }}
      >
        <nine-info-container slot="content">
          <nine-spacer s="md"></nine-spacer>
          {content.image && <content.image {...itemProps} />}
          <nine-spacer s="md"></nine-spacer>
          <h5 className="strong m0">
            {content.title && <content.title {...itemProps} />}
          </h5>
          <p className="color-c-80">
            {content.subtitle && <content.subtitle {...itemProps} />}
          </p>
          <nine-spacer s="xs"></nine-spacer>
          {content.highlights && <content.highlights {...itemProps} />}
          <nine-spacer s="xs"></nine-spacer>
          <p className="strong">
            {content.note && <content.note {...itemProps} />}
          </p>

          {content.list && <content.list {...itemProps} />}
          <nine-spacer s="xs"></nine-spacer>
          <nine-center>
            <EnrollButton />
          </nine-center>
          <nine-spacer s="md"></nine-spacer>
        </nine-info-container>
      </nine-info-section>
    </>
  );
};

export default CDCDPPCustomizedCheckoutScreen;
