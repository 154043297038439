import styled from "@emotion/styled";

export const NiceError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  h1 {
    text-align: center;
    text-wrap: pretty;
    font-size: 2rem;
    padding: 1rem;
    font-weight: 400;
  }

  em {
    font-style: italic;
  }

  pre {
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    padding: 1rem;
    background-color: rgb(240, 240, 240);
    border-radius: 0.5rem;
    max-width: 90vw;
    text-align: left;
  }
`;
