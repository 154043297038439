import React from "react";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { useBloc } from "src/state/state";

const DebugOnboardingSteps = () => {
  const [{ showOnboardingScreen }, { setShowOnboardingScreen }] =
    useBloc(AppViewCubit);

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={showOnboardingScreen === true}
          onChange={() => {
            setShowOnboardingScreen(!showOnboardingScreen);
          }}
        />
        <span style={{ marginLeft: "4px", fontSize: "1rem" }}>
          Show Onboarding Screen
        </span>
      </label>
    </div>
  );
};

export default DebugOnboardingSteps;
