import type {
  AppliedCouponResponse,
  GetSubscriptionPricesResponse,
  PurchaseItemResponse
} from "@9amhealth/openapi";
import {
  AddSubscriptionItemRequest,
  GetSuggestedTreatmentPlanResponse,
  RemoveSubscriptionItemRequest,
  SubscriptionControllerService,
  SubscriptionDetailsResponse,
  TreatmentPlanControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import { DiscountFactor } from "src/constants/discountFactors";
import FunnelKey from "src/constants/funnelKey";
import { globalEvents } from "src/constants/globalEvents";
import {
  MEDICARE_PROGRAM_SKU,
  PHLEBOTOMY_SKU,
  PRESCRYPTIVE_PROGRAM_ITEM_SKU,
  WEIGHT_LOSS_PROGRAM_SKU
} from "src/constants/misc";
import {
  LANCETS_SKU,
  STRIPS_METER_SKU,
  TEST_STRIPS_SKU
} from "src/constants/purchaseItemsSku";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { checkItemType } from "src/lib/checkItemType";
import { dateLocal } from "src/lib/date";
import type { ParsedPurchaseItemResponse } from "src/lib/parsePurchaseItem";
import parsePurchaseItem from "src/lib/parsePurchaseItem";
import reportErrorSentry from "src/lib/reportErrorSentry";
import roundPriceValue from "src/lib/roundPriceValue";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import type { SubscriptionState } from "src/state/SubscriptionCubit/SubscriptionState";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import {
  ItemType,
  SubscriptionInterval
} from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import {
  apiMiddleware,
  loadingState,
  tracker,
  userState
} from "src/state/state";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import { StorageController } from "../StorageBloc/StorageBloc";

/**
 * The Payer IDs.
 * Defines the payer of the subscription.
 * - Used in the metadata of the purchase items.
 *    - "partner.payer": "79a815d6-262c-46e4-a20d-d942b79f8fee"
 * CASH_PAY = user pays with credit card
 * OTHER = users insurance or employer pays
 */
export enum PayerId {
  CASH_PAY = "79a815d6-262c-46e4-a20d-d942b79f8fee",
  TRANSCARENT = "9c201a22-bd05-41c8-b885-ab17b1c73330"
}

export type SubscriptionId = string | (() => string | undefined) | undefined;

export default class SubscriptionCubit extends Cubit<SubscriptionState> {
  isDemoFunnel = false;
  constructor() {
    super({});

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({});
    });

    const cached =
      StorageController.activeUserId &&
      StorageController.getItem(this.cacheKey);
    if (cached) {
      this.emit(JSON.parse(cached) as SubscriptionState);
    }
  }

  cacheKey = "subscriptions";
  emitAndCache = (state: SubscriptionState): void => {
    this.emit(state);
    StorageController.setItem(this.cacheKey, JSON.stringify(state));
  };

  reset = (): void => {
    this.emitAndCache({});
  };

  setDemoFunnel = (isDemoFunnel: boolean): void => {
    this.isDemoFunnel = isDemoFunnel;
  };

  public readonly getFullSubscription = (
    status?: SubscriptionDetailsResponse.status[]
  ): SubscriptionDetailsResponse | undefined => {
    const filtered = this.filterAllSubscriptions({
      duration: [SubscriptionInterval.monthly, SubscriptionInterval.quarterly],
      status
    });
    return filtered[0] as SubscriptionDetailsResponse | undefined;
  };

  public readonly setCouponCode = async (coupon: string): Promise<void> => {
    this.emitAndCache({ ...this.state, coupon });
    if (coupon) {
      tracker.track(TrackEvent.setCouponCode, {
        data: { "Coupon Code": coupon }
      });
      try {
        await this.reserveCouponCode(coupon);
        tracker.track("Reserve Coupon Code" as TrackEvent, {
          data: { "Coupon Code": coupon }
        });
      } catch (error) {
        reportErrorSentry(error);
      }
    }
  };

  public readonly reserveCouponCode = async (coupon: string): Promise<void> => {
    if (coupon) {
      loadingState.start(LoadingKey.coupon);
      try {
        await SubscriptionControllerService.reserveCoupon({ coupon });
      } catch (error: unknown) {
        reportErrorSentry(error);
      }
      loadingState.finish(LoadingKey.coupon);
    }
  };

  public readonly getPlanItems = (
    subscriptionId: SubscriptionId
  ): PurchaseItemResponse[] => {
    if (!subscriptionId) return [];
    return this.getSubscriptionPurchaseItems(subscriptionId, [
      ItemType.SUBSCRIPTION_PLAN_SUGGESTION,
      ItemType.MEDICATION_SUGGESTION,
      ItemType.PRELIMINARY_MEDICATION_SUGGESTION,
      ItemType.LAB_TEST_SUGGESTION,
      ItemType.OVER_THE_COUNTER_STRIP_ITEM,
      ItemType.OVER_THE_COUNTER_LAB_TEST
    ]);
  };

  public readonly getSubscriptionById = (
    subscriptionId: SubscriptionId
  ): SubscriptionDetailsResponse | undefined => {
    if (!subscriptionId) return;
    const subId =
      typeof subscriptionId === "function" ? subscriptionId() : subscriptionId;
    const allSubs = this.state.allSubscriptions ?? [];
    const match = allSubs.find((subscription) => subscription.id === subId);
    // typecast SubscriptionDetailsResponse to SubscriptionDetailsResponse
    return match;
  };

  /**
   * Adds the subscriptions prices for all periods, immediate, 1month and 3month
   * @returns number: price of the subscription
   */
  public readonly getPlanItemsPrice = (
    subscriptionId: SubscriptionId
  ): number => {
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription) return 0;
    const { P0D, P1M, P3M } = this.getPricingPerPeriod(subscription);
    return roundPriceValue(P0D + P1M + P3M);
  };

  public readonly isFreeMonthCoupon = (
    subscriptionId: SubscriptionId
  ): boolean => {
    if (!subscriptionId) return false;
    const appliedCoupon = this.getAppliedCoupon(subscriptionId);

    return Boolean(
      appliedCoupon?.durationInMonths === 3 &&
        appliedCoupon.discountFactor === DiscountFactor.ZERO_POINT_THREE_THREE
    );
  };

  public readonly isCouponApplicable = (
    subscriptionId: SubscriptionId
  ): boolean => {
    if (!subscriptionId) return false;
    const subscription = this.getSubscriptionById(subscriptionId);
    const appliedCoupon = this.getAppliedCoupon(subscriptionId);

    return Boolean(
      appliedCoupon?.applicableSubscriptionRenewalIntervals.includes(
        subscription?.renewalInterval ?? ""
      )
    );
  };

  private readonly getPricingPerPeriod = (
    subscription: SubscriptionDetailsResponse
  ): Record<SubscriptionInterval, number> => {
    const pricesPerPeriod = subscription.pricesPerPeriod as Record<
      SubscriptionInterval,
      number | undefined
    >;
    return {
      [SubscriptionInterval.oneTime]:
        pricesPerPeriod[SubscriptionInterval.oneTime] ?? 0,
      [SubscriptionInterval.monthly]:
        pricesPerPeriod[SubscriptionInterval.monthly] ?? 0,
      [SubscriptionInterval.quarterly]:
        pricesPerPeriod[SubscriptionInterval.quarterly] ?? 0
    };
  };

  /**
   * Duration of the subscription, either 1 or 3
   * Symbol: `D`
   */
  public readonly getPaymentInterval = (
    subscriptionId: SubscriptionId
  ): 1 | 3 => {
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription) return 1;

    if (
      (subscription.paymentInterval as SubscriptionInterval) ===
      SubscriptionInterval.monthly
    ) {
      return 1;
    }

    if (
      (subscription.paymentInterval as SubscriptionInterval) ===
      SubscriptionInterval.quarterly
    ) {
      return 3;
    }

    return 1;
  };

  /**
   * Final price the user pays
   * Symbol: `F`
   * @param options
   * @returns
   */
  public readonly getFinalPrice = (
    subscriptionId: SubscriptionId,
    paymentInterval?: SubscriptionInterval
  ): number => {
    const subscription = this.getSubscriptionById(subscriptionId);
    const useInterval = (paymentInterval ??
      subscription?.paymentInterval) as SubscriptionInterval;
    if (!subscription) return 0;
    // TD
    const totalPrice = this.getTotalPrice(subscriptionId, {
      multiply: useInterval === SubscriptionInterval.quarterly
    });
    // S
    const savings = this.getSavings(subscriptionId, useInterval);
    // TD - S
    return roundPriceValue(totalPrice - savings);
  };

  /**
   * Final price the user pays per month
   * Symbol: `FM`
   */
  public readonly getFinalPriceMonthly = (
    subscriptionId: SubscriptionId
  ): number => {
    // F
    const finalPrice = this.getFinalPrice(subscriptionId);
    // D
    const paymentInterval = this.getPaymentInterval(subscriptionId);

    // F / D
    return roundPriceValue(finalPrice / paymentInterval);
  };

  /**
   * total price without discount, per month or per quarter
   * Symbol: `T` or `TD` if multiplied P3M by duration
   *
   * @param options.multiply if true will multiply by duration of subscription (min 1)
   */
  public readonly getTotalPrice = (
    subscriptionId: SubscriptionId,
    options: { multiply?: boolean } = {}
  ): number => {
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription) return 0;

    const factor = options.multiply
      ? this.getPaymentInterval(subscriptionId)
      : 1;

    const { P0D, P1M, P3M } = this.getPricingPerPeriod(subscription);
    const totalP3M = P3M * factor;

    return roundPriceValue(P0D + P1M + totalP3M);
  };

  /**
   * Total price of all recurring items
   * Symbol: `SP`
   */
  public readonly getSubscriptionPrice = (
    subscriptionId: SubscriptionId,
    options: { multiply?: boolean } = {}
  ): number => {
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription) return 0;
    const { P0D, P1M, P3M } = this.getPricingPerPeriod(subscription);
    const factor = options.multiply
      ? this.getPaymentInterval(subscriptionId)
      : 1;

    const totalP3M = P3M * factor;
    const rollingPrice = roundPriceValue(P1M + totalP3M);
    return rollingPrice !== 0 ? rollingPrice : roundPriceValue(P0D);
  };

  /**
   * Savings for the user for the final price
   * Symbol: `S`
   * If the coupon has `amountOff` then: S = amountOff
   * If coupon has `discountFactor`:
   *  - and "multiply" is true, then: S = TD * discountFactor
   *  - and "multiply" is false, then: S = T * discountFactor
   */
  public readonly getSavings = (
    subscriptionId?: SubscriptionId,
    paymentInterval?: SubscriptionInterval
  ): number => {
    const subscription = this.getSubscriptionById(subscriptionId);
    const coupon = this.getAppliedCoupon(subscriptionId);
    const useInterval = (paymentInterval ??
      subscription?.paymentInterval) as SubscriptionInterval;
    if (!subscription) return 0;

    if (coupon?.amountOff) {
      return roundPriceValue(coupon.amountOff);
    }

    // T or TD
    const totalPrice = this.getTotalPrice(subscriptionId, {
      multiply: useInterval === SubscriptionInterval.quarterly
    });

    // discount factor, value from 0 to 1
    const discountFactor = coupon?.discountFactor ?? 0;
    return roundPriceValue(totalPrice * discountFactor);
  };

  public readonly getAppliedCoupon = (
    subscriptionId: SubscriptionId
  ): AppliedCouponResponse | undefined => {
    if (!subscriptionId) return;
    const subscription = this.getSubscriptionById(subscriptionId);
    return (subscription?.appliedCoupons ?? [])[0];
  };

  readonly isAtHomeLabTestChosen = (
    subscriptionId: SubscriptionId
  ): boolean => {
    if (!subscriptionId) return false;
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription?.purchaseItems) {
      return false;
    }
    const labTestFilter = subscription.purchaseItems.filter((item) =>
      checkItemType(item.externalItemType, ItemType.LAB_TEST_SUGGESTION)
    );
    return labTestFilter.length !== 0;
  };

  readonly isTestStripsChosen = (subscriptionId: SubscriptionId): boolean => {
    if (!subscriptionId) return false;
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription?.purchaseItems) {
      return false;
    }
    const labTestFilter = subscription.purchaseItems.filter((item) =>
      checkItemType(item.externalItemType, ItemType.LAB_TEST_SUGGESTION)
    );
    return labTestFilter.length === 1;
  };

  readonly getItemBySku = (
    subscriptionId: SubscriptionId,
    sku: string
  ): PurchaseItemResponse | undefined => {
    if (!subscriptionId) return;
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription?.purchaseItems) {
      return;
    }
    return subscription.purchaseItems.find((i) => i.sku === sku);
  };

  /**
   * Add or remove a purchase item in the subscription, or update the quantity
   * @param sku Item SKU
   * @param set set to true to add the item, false to remove it
   * @param options options to pass to the API
   * @returns Promise that resolves true when the item has been added or removed, false if there was an error
   */

  readonly toggleItemBySku = async (
    subscriptionId: SubscriptionId,
    sku: string,
    set: boolean, // set = remove or add item
    options: {
      quantity?: number;
      provider?: AddSubscriptionItemRequest.provider;
      autoRemoveRelatedItems?: boolean;
    } = {}
  ): Promise<boolean> => {
    if (!subscriptionId) return false;
    const subscription = this.getSubscriptionById(subscriptionId);
    let success = true;
    // if there is no subscription, we can't add or remove items
    if (!subscription) {
      reportErrorSentry("SubscriptionCubit.toggleItemBySku: no subscription");
      return false;
    }

    // get the current items
    const currentItems = subscription.purchaseItems;
    // search for the item by sku
    const currentItem = currentItems.find((item) => item.sku === sku);
    const subId = subscription.id;

    // start loading
    loadingState.start(LoadingKey.subscriptionState);

    try {
      if (set && !currentItem) {
        // if we want to add the item, and it's not already in the list, add it
        await SubscriptionControllerService.addSubscriptionItem(subId, {
          sku,
          // The default provider is "OTC"
          provider: options.provider ?? AddSubscriptionItemRequest.provider.OTC
        });
      } else if (set && currentItem) {
        // if set is true and there is an item, update the item
        let validUpdate = true;
        const itemQuantity = currentItem.quantity ?? 1; // the actual number of items
        const priceQuantity = currentItem.priceQuantity ?? 1; // the number of items in "one" item - e.g. one "box" has 100 items
        const itemsCount = itemQuantity / priceQuantity; // the number of items in the subscription - how many "boxes"

        const setQuantity = options.quantity ?? itemsCount;
        const newQuantity = setQuantity * priceQuantity;
        const changes: boolean[] = [newQuantity !== itemQuantity];

        if (changes.some((b) => !b)) {
          reportErrorSentry(
            "SubscriptionCubit.toggleItemBySku: trying to update item, but all data is the same"
          );
          validUpdate = false;
          success = false;
        }

        const validQuantityList: number[] = (currentItem.metadata[
          "quantity.available.values"
        ] as number[] | undefined) ?? [1];
        const validQuantity = validQuantityList.includes(newQuantity);
        if (!validQuantity) {
          reportErrorSentry(
            "SubscriptionCubit.toggleItemBySku: trying to update item, but quantity is not valid"
          );
          validUpdate = false;
          success = false;
        }

        // only update if we think its valid
        if (validUpdate)
          await SubscriptionControllerService.updateSubscriptionItem(
            subId,
            currentItem.id,
            {
              quantity: newQuantity
            }
          );
      }

      if (!set && currentItem) {
        // if we want to remove the item, and it's in the list, remove it
        await SubscriptionControllerService.removeSubscriptionItem(subId, {
          itemToExpireIds: [currentItem.id],
          expirationReason:
            RemoveSubscriptionItemRequest.expirationReason.UNKNOWN
        });
      }

      // update the subscription state
      await this.refreshSubscription(subId);
    } catch (e: unknown) {
      reportErrorSentry(e);
      success = false;
    }

    loadingState.finish(LoadingKey.subscriptionState);
    return success;
  };

  readonly refreshSubscription = async (subId: string): Promise<void> => {
    // start loading
    loadingState.start(LoadingKey.subscriptionState);
    try {
      // get the subscription details
      const response =
        await SubscriptionControllerService.subscriptionDetails(subId);
      this.insertSubscription(response.data);
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    // finish loading
    loadingState.finish(LoadingKey.subscriptionState);
  };

  readonly setRenewalInterval = async (
    subscriptionId: SubscriptionId,
    frequency: SubscriptionInterval
  ): Promise<void> => {
    if (!subscriptionId) return;
    const subscription = this.getSubscriptionById(subscriptionId);
    loadingState.start(LoadingKey.subscriptionState);
    if (
      subscription &&
      (subscription.renewalInterval as SubscriptionInterval) !== frequency
    ) {
      const { id } = subscription;
      try {
        loadingState.start(LoadingKey.treatmentPlanFrequency);
        await SubscriptionControllerService.changeRenewalInterval(id, {
          renewalInterval: frequency
        });

        tracker.track(TrackEvent.subFrequency, {
          type: TrackType.changed,
          data: {
            "Selected Duration": frequency
          }
        });

        await this.getSubscriptionDetails(id);
      } catch (e: unknown) {
        reportErrorSentry(e);
      } finally {
        loadingState.finish(LoadingKey.treatmentPlanFrequency);
      }
    }
    loadingState.finish(LoadingKey.subscriptionState);
  };

  readonly fetchAndFilterSubscriptions = async (
    filter: {
      oneTime?: boolean;
      durations?: SubscriptionInterval[];
      status?: SubscriptionDetailsResponse.status[];
      items?: string[];
      ignoreItems?: string[];
      looseCheck?: boolean;
    } = {}
  ): Promise<SubscriptionDetailsResponse | undefined> => {
    loadingState.start(LoadingKey.subscriptionState);
    let subscription: SubscriptionDetailsResponse | undefined;
    try {
      await this.loadAllSubscriptions();
      const list = this.state.allSubscriptions ?? [];
      if (list.length === 0) {
        loadingState.finish(LoadingKey.subscriptionState);

        return;
      }

      const items = this.filterAllSubscriptions({
        duration:
          // use filter.durations first, if undefined use default durations
          filter.durations?.length
            ? filter.durations
            : filter.oneTime
              ? [SubscriptionInterval.oneTime]
              : [SubscriptionInterval.monthly, SubscriptionInterval.quarterly],
        status: filter.status,
        items: filter.items,
        ignoreItems: filter.ignoreItems,
        looseCheck: filter.looseCheck
      });

      // newest subscription from all subscriptions
      items.sort((a, b) => {
        return dateLocal(a.createdDate).unix() < dateLocal(b.createdDate).unix()
          ? -1
          : 1;
      });

      subscription = items[0] as SubscriptionDetailsResponse | undefined;
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.subscriptionState);
    return subscription;
  };

  readonly knownTreatmentPlanTargets = {
    CDC_DPP: KnownProgram.CDC_DPP
  } as const;
  readonly checkTreatmentTargetEligibility = async (
    target: string
  ): Promise<GetSuggestedTreatmentPlanResponse | undefined> => {
    loadingState.start(LoadingKey.subscriptionState);
    let eligibility: GetSuggestedTreatmentPlanResponse | undefined;
    try {
      const response =
        await TreatmentPlanControllerService.suggestTreatmentPlanForTarget(
          target
        );
      eligibility = response.data;
    } catch (error: unknown) {
      reportErrorSentry(error);
    }
    loadingState.finish(LoadingKey.subscriptionState);
    return eligibility;
  };

  private loadAllSubscriptionsPromise:
    | Promise<SubscriptionDetailsResponse[]>
    | undefined;

  readonly loadAllSubscriptions = async (
    options: { cachedSubscriptionsAcceptable?: boolean } = {}
  ): Promise<void> => {
    if (
      options.cachedSubscriptionsAcceptable &&
      typeof this.state.allSubscriptions !== "undefined"
    ) {
      return;
    }

    let promise = this.loadAllSubscriptionsPromise;

    if (!promise) {
      promise = new Promise((resolve, reject) => {
        void SubscriptionControllerService.listSubscriptions()
          .then((d) => resolve(d.data))
          .catch((e) => reject(e as Error))
          .finally(() => {
            this.loadAllSubscriptionsPromise = undefined;
          });
      });
      this.loadAllSubscriptionsPromise = promise;
    }

    try {
      const response = await promise;

      // sort, newest first
      response.sort(
        (a, b) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );

      const isSame =
        JSON.stringify(this.state.allSubscriptions) ===
        JSON.stringify(response);
      if (isSame) {
        return;
      }

      const newState: SubscriptionState = {
        ...this.state,
        allSubscriptions: response,
        hiddenItems: []
      };

      this.emitAndCache(newState);
    } catch (error: unknown) {
      reportErrorSentry(error);
    }
  };

  readonly filterAllSubscriptions = (filter: {
    status?: SubscriptionDetailsResponse.status[];
    duration?: SubscriptionInterval[];
    items?: string[];
    ignoreItems?: string[];
    metadataPartnerFunnel?: string[];
    metadataPayerId?: PayerId;
    /**
     * If looseCheck is true, the response can include subscriptions
     * which contain at least the SKUs defined in
     * "items", but can include more
     * -- Ignores: "ignoreItems"
     */
    looseCheck?: boolean;
  }): SubscriptionDetailsResponse[] => {
    const list = this.state.allSubscriptions ?? [];
    // sort
    list.sort((a, b) => {
      return dateLocal(a.createdDate).unix() < dateLocal(b.createdDate).unix()
        ? -1
        : 1;
    });

    // filter
    return list.filter((subscription) => {
      const matchStatus = filter.status
        ? filter.status.indexOf(subscription.status) !== -1
        : true;

      const matchDuration = filter.duration
        ? filter.duration.indexOf(
            subscription.renewalInterval as SubscriptionInterval
          ) !== -1
        : true;

      let itemsMatch = true;

      const filterItems = filter.items;
      if (filterItems) {
        const ignoreItems: string[] = [];
        const { purchaseItems } = subscription;

        if (filter.ignoreItems) {
          ignoreItems.push(...filter.ignoreItems);
        }

        if (filter.looseCheck) {
          const itemsIncludedInSubscription = purchaseItems
            .filter((item) => filterItems.includes(item.sku))
            .map((item) => {
              return item.sku;
            });

          itemsMatch =
            itemsIncludedInSubscription.length === filterItems.length;
        } else {
          const purchaseItemsFiltered = purchaseItems.filter(
            (i) => !ignoreItems.includes(i.sku as unknown as string)
          );

          const itemsMatchFilter = purchaseItemsFiltered.map((purchaseItem) => {
            return (
              filterItems.indexOf(purchaseItem.sku as unknown as string) !== -1
            );
          });

          itemsMatch = !itemsMatchFilter.some((itemMatch) => !itemMatch);
        }
      }

      let matchMetadata = true;

      // filter for metadataPartnerFunnel
      const filterMetadataPartnerFunnel = filter.metadataPartnerFunnel;
      if (filterMetadataPartnerFunnel) {
        const { purchaseItems } = subscription;

        matchMetadata = purchaseItems.some((purchaseItem) =>
          filterMetadataPartnerFunnel.includes(
            purchaseItem.metadata["partner.funnel"] as string
          )
        );
      }

      // filter for metadata payerId
      const filterMetadataPayerId = filter.metadataPayerId;
      if (filterMetadataPayerId) {
        const { purchaseItems } = subscription;

        matchMetadata = purchaseItems.some(
          (purchaseItem) =>
            filterMetadataPayerId ===
            (purchaseItem.metadata["partner.payer"] as PayerId | string)
        );
      }

      return matchStatus && matchDuration && itemsMatch && matchMetadata;
    });
  };

  get hasActiveSubscription(): boolean | undefined {
    if (this.state.allSubscriptions === undefined) return undefined;

    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.PAUSED,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates
      }).length > 0
    );
  }

  get hasEverHadSubscription(): boolean | undefined {
    if (this.state.allSubscriptions === undefined) return undefined;

    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.PAUSED,
      SubscriptionDetailsResponse.status.IN_REVIEW,
      SubscriptionDetailsResponse.status.REJECTED,
      SubscriptionDetailsResponse.status.FINISHED
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates
      }).length > 0
    );
  }

  get hasInactiveSubscription(): boolean | undefined {
    if (this.state.allSubscriptions === undefined) return undefined;

    const allowedStates = [
      SubscriptionDetailsResponse.status.PAUSED,
      SubscriptionDetailsResponse.status.REJECTED,
      SubscriptionDetailsResponse.status.FINISHED
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates
      }).length > 0
    );
  }

  get hasHomePhlebotomySubscription(): boolean {
    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.IN_REVIEW,
      SubscriptionDetailsResponse.status.DRAFT
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates,
        items: [PHLEBOTOMY_SKU],
        looseCheck: true
      }).length > 0
    );
  }

  get a1cSubscriptions(): SubscriptionDetailsResponse[] {
    const allowedStates = [
      SubscriptionDetailsResponse.status.FINISHED,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ];
    const allowedDurations = [SubscriptionInterval.oneTime];
    return this.filterAllSubscriptions({
      status: allowedStates,
      duration: allowedDurations
    });
  }

  get hasTestStripsSubscription(): boolean {
    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates,
        items: [TEST_STRIPS_SKU],
        looseCheck: true
      }).length > 0
    );
  }

  get hasBoughtFullSubscription(): boolean {
    const allowedDurations = [
      SubscriptionInterval.monthly,
      SubscriptionInterval.quarterly
    ];
    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.PAUSED,
      SubscriptionDetailsResponse.status.IN_REVIEW,
      SubscriptionDetailsResponse.status.REJECTED,
      SubscriptionDetailsResponse.status.FINISHED
    ];
    return (
      this.filterAllSubscriptions({
        status: allowedStates,
        duration: allowedDurations
      }).length > 0
    );
  }

  readonly initSubscription = async (options: {
    /** Use existing subscriptions if the period matches, if none match create a new sub. with this period */
    renewalInterval: SubscriptionInterval;
    /** Payment interval for subscription, defaults to P1M */
    paymentInterval?: SubscriptionInterval;
    /** Create subscription with the default care plan items */
    useTreatmentPlan?: boolean;
    /** Items in the subscription, must be an exact match. If no subscription is found with exactly these items, new one will be created, and these items will be added */
    items?: string[];
    /** Ignore these items when checking if `items` match */
    ignoreItems?: string[];
    /** Use existing subscriptions if the status matches */
    status?: SubscriptionDetailsResponse.status[];
    /** Adds coupon code to subscription */
    couponCode?: string;
  }): Promise<void> => {
    const {
      renewalInterval,
      paymentInterval,
      useTreatmentPlan = true
    } = options;
    try {
      let subId = "";
      const selected = await this.fetchAndFilterSubscriptions({
        oneTime: renewalInterval === SubscriptionInterval.oneTime,
        status: options.status ?? [
          SubscriptionDetailsResponse.status.DRAFT,
          SubscriptionDetailsResponse.status.ACTIVE,
          SubscriptionDetailsResponse.status.PAUSED,
          SubscriptionDetailsResponse.status.IN_REVIEW
        ],
        items: options.items,
        ignoreItems: options.ignoreItems
      });

      subId = selected?.id ?? "";

      if (!subId) {
        subId = await this.createSubscription(useTreatmentPlan, {
          renewalInterval,
          paymentInterval,
          items: options.items
        });
      }

      await this.applySubscriptionCoupon(subId, options.couponCode);
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  readonly applySubscriptionCoupon = async (
    subscriptionId: SubscriptionId,
    couponCode?: string
  ): Promise<void> => {
    if (!subscriptionId) return;
    const subscription = this.getSubscriptionById(subscriptionId);
    const subscriptionIdString = subscription?.id;
    const coupon = couponCode ?? this.state.coupon;
    const { appliedCoupons = [] } = subscription ?? {};

    for (const applied of appliedCoupons) {
      if (applied.coupon === coupon) {
        void this.setCouponCode("");
        return;
      }
    }

    try {
      if (subscriptionIdString && coupon) {
        const details = await SubscriptionControllerService.applyCoupon(
          subscriptionIdString,
          {
            coupon
          }
        );
        this.insertSubscription(details.data);
        if (!couponCode) {
          void this.setCouponCode("");
        }
        tracker.track(TrackEvent.couponApplied, {
          data: { "Coupon Code": coupon }
        });
      }
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  /*
    Removes all coupons from a subscription
   */
  readonly removeSubscriptionCoupon = async (
    subscriptionId: SubscriptionId
  ): Promise<void> => {
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscription) return;
    const subscriptionIdString = subscription.id;
    const { appliedCoupons = [] } = subscription;
    if (!appliedCoupons.length) return;

    try {
      await SubscriptionControllerService.removeCoupon(subscriptionIdString);

      this.insertSubscription({ ...subscription, appliedCoupons: [] });

      await this.setCouponCode("");
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  /* Removes coupons from test strips subscription */
  readonly removeTestStripsDraftSubscriptionCoupons =
    async (): Promise<void> => {
      const targetSubscription = await this.fetchAndFilterSubscriptions({
        status: [SubscriptionDetailsResponse.status.DRAFT],
        items: [TEST_STRIPS_SKU],
        ignoreItems: [STRIPS_METER_SKU, LANCETS_SKU]
      });

      if (targetSubscription)
        await this.removeSubscriptionCoupon(targetSubscription.id);
    };

  readonly createSubscription = async (
    useTreatmentPlan: boolean,
    options: {
      renewalInterval: SubscriptionInterval;
      items?: string[];
      paymentInterval?: SubscriptionInterval;
      coupon?: string;
    }
  ): Promise<string> => {
    const { renewalInterval, items, paymentInterval } = options;
    loadingState.start(LoadingKey.subscriptionState);
    let subscriptionId = "";
    try {
      loadingState.start(LoadingKey.treatmentPlan);
      const { data } = await SubscriptionControllerService.createSubscription(
        String(useTreatmentPlan),
        renewalInterval,
        paymentInterval
      );
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      subscriptionId = data.subscriptionId;
      await this.getSubscriptionDetails(subscriptionId);
    } catch (e: unknown) {
      reportErrorSentry(e);
    } finally {
      loadingState.finish(LoadingKey.treatmentPlan);
    }

    if (options.coupon) {
      try {
        await this.applySubscriptionCoupon(subscriptionId, options.coupon);
      } catch (e: unknown) {
        reportErrorSentry(e);
      }
    }

    if (subscriptionId) {
      try {
        if (items) {
          for (const item of items) {
            await this.toggleItemBySku(subscriptionId, item, true);
          }
        }
      } catch (e: unknown) {
        reportErrorSentry(e);
      }
    }

    loadingState.finish(LoadingKey.subscriptionState);
    return subscriptionId;
  };

  readonly buySubscription = async (
    subscriptionId: SubscriptionId,
    options: {
      onError?: () => void;
      onSuccess?: () => void;
    } = {}
  ): Promise<boolean> => {
    if (!subscriptionId) return false;
    let success = false;
    apiMiddleware.clearAll();
    const subscription = this.getSubscriptionById(subscriptionId);
    const id = subscription?.id;
    if (!id) {
      return false;
    }

    try {
      loadingState.start(LoadingKey.treatmentPlan);

      await SubscriptionControllerService.buySubscription(id);

      if (this.isDemoFunnel) {
        await userState.populateUserprofileWithDemoData();
      }

      await this.getSubscriptionDetails(id);
      options.onSuccess?.();
      success = true;
    } catch (e: unknown) {
      reportErrorSentry(e);
      options.onError?.();
      success = false;
    }

    loadingState.finish(LoadingKey.treatmentPlan);
    apiMiddleware.clearAll();
    return success;
  };

  readonly enrollFreeSubscription = async (): Promise<void> => {
    loadingState.start(LoadingKey.treatmentPlan);
    const draftSubscription = await this.fetchAndFilterSubscriptions({
      status: [SubscriptionDetailsResponse.status.DRAFT]
    });

    const price = draftSubscription?.totalPrice;

    if (!draftSubscription || typeof price !== "number" || price > 0) {
      reportErrorSentry(
        new Error(
          `No draft subscription found or subscription price is not 0. Price: ${price}`
        )
      );
      loadingState.finish(LoadingKey.treatmentPlan);
      return;
    }

    void this.buySubscription(draftSubscription.id).finally(() => {
      loadingState.finish(LoadingKey.treatmentPlan);
    });
  };

  readonly getSubscriptionDetails = async (
    subscriptionId: string
  ): Promise<void> => {
    loadingState.start(LoadingKey.treatmentPlan);
    try {
      const details =
        await SubscriptionControllerService.subscriptionDetails(subscriptionId);
      this.insertSubscription(details.data);
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.treatmentPlan);
  };

  readonly getSubscriptionPurchaseItems = (
    subscriptionId: SubscriptionId,
    itemType: ItemType | ItemType[]
  ): PurchaseItemResponse[] => {
    if (!subscriptionId) return [];
    if (!Array.isArray(itemType)) {
      itemType = [itemType];
    }
    const subscription = this.getSubscriptionById(subscriptionId);
    return (subscription?.purchaseItems ?? [])
      .filter((item) =>
        itemType.includes(item.externalItemType as unknown as ItemType)
      )
      .sort((a, b) => {
        // sort alphabetically by sku
        return b.sku < a.sku ? -1 : 1;
      });
  };

  readonly getPricingForSubscription = async (
    subscriptionId: SubscriptionId
  ): Promise<GetSubscriptionPricesResponse | undefined> => {
    const subscription = this.getSubscriptionById(subscriptionId);
    if (!subscriptionId || !subscription) return;

    loadingState.start(LoadingKey.treatmentPlan);

    let pricing = undefined;
    try {
      const priceData =
        await SubscriptionControllerService.getSubscriptionPricing(
          subscription.id
        );
      pricing = priceData.data;
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
    loadingState.finish(LoadingKey.treatmentPlan);
    return pricing;
  };

  readonly insertSubscription = (
    subscription: SubscriptionDetailsResponse
  ): void => {
    const currentList = this.state.allSubscriptions ?? [];

    let replacedCurrent = false;
    const allSubscriptions: SubscriptionDetailsResponse[] = [];

    for (const sub of currentList) {
      if (sub.id === subscription.id) {
        replacedCurrent = true;
        allSubscriptions.push(subscription);
      } else {
        allSubscriptions.push(sub);
      }
    }

    if (!replacedCurrent) {
      allSubscriptions.push(subscription);
    }

    this.emitAndCache({
      ...this.state,
      allSubscriptions
    });
  };

  readonly getParsedMeds = (
    subscriptionId: SubscriptionId
  ): ParsedPurchaseItemResponse[] => {
    if (!subscriptionId) return [];
    return [
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.MEDICATION_SUGGESTION
      ),
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.PRESCRIBED_MEDICATION
      ),
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.PRELIMINARY_MEDICATION_SUGGESTION
      ),
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.prescription
      )
    ].map(parsePurchaseItem);
  };

  readonly getParsedSupplies = (
    subscriptionId: SubscriptionId
  ): ParsedPurchaseItemResponse[] => {
    if (!subscriptionId) return [];
    return [
      ...this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.PRESCRIBED_SUPPLY
      )
    ].map(parsePurchaseItem);
  };

  readonly checkHasLabs = (
    subscriptionId: SubscriptionId
  ): PurchaseItemResponse | undefined => {
    if (!subscriptionId) return;
    return this.getSubscriptionPurchaseItems(
      subscriptionId,
      ItemType.LAB_TEST_SUGGESTION
    )[0] as PurchaseItemResponse | undefined;
  };

  readonly checkHasTestStrips = (): PurchaseItemResponse | undefined => {
    const allowedStates = [
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ];

    const subscription = this.filterAllSubscriptions({
      status: allowedStates,
      items: [TEST_STRIPS_SKU],
      looseCheck: true
    })[0] as SubscriptionDetailsResponse | undefined;

    return this.getSubscriptionPurchaseItems(
      subscription?.id,
      ItemType.OVER_THE_COUNTER_STRIP_ITEM
    )[0] as PurchaseItemResponse | undefined;
  };

  readonly checkHasPlan = (
    subscriptionId: SubscriptionId
  ): PurchaseItemResponse | undefined => {
    if (!subscriptionId) return;
    return this.getSubscriptionPurchaseItems(
      subscriptionId,
      ItemType.SUBSCRIPTION_PLAN_SUGGESTION
    )[0] as PurchaseItemResponse | undefined;
  };

  readonly checkHasPlaceholderMedications = (
    subscriptionId: SubscriptionId
  ): boolean => {
    if (!subscriptionId) return false;

    return (
      this.getSubscriptionPurchaseItems(
        subscriptionId,
        ItemType.PRELIMINARY_MEDICATION_SUGGESTION
      ).length > 0
    );
  };

  readonly getItemsById = (
    subId: SubscriptionId,
    ids: string[]
  ): PurchaseItemResponse[] => {
    const sub = this.getSubscriptionById(subId);
    return sub?.purchaseItems.filter((item) => ids.includes(item.id)) ?? [];
  };

  readonly setHiddenItems = (itemIds: string[], hidden: boolean): void => {
    const allHidden = new Set(this.state.hiddenItems ?? []);
    for (const item of itemIds) {
      if (hidden) {
        allHidden.add(item);
      } else {
        allHidden.delete(item);
      }
    }

    this.emitAndCache({
      ...this.state,
      hiddenItems: Array.from(allHidden)
    });
  };

  readonly checkUserHasMedicareSubscription = async (): Promise<boolean> => {
    await this.loadAllSubscriptions();
    const sub = this.getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ]);
    const subHasMedicareItem = Boolean(
      sub?.purchaseItems.some((item) => item.sku === MEDICARE_PROGRAM_SKU)
    );
    return subHasMedicareItem;
  };

  readonly checkUserHasGlpSubscription = async (): Promise<boolean> => {
    await this.loadAllSubscriptions();
    const sub = this.getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ]);
    const subHasGlp1Item = Boolean(
      sub?.purchaseItems.some((item) => item.sku === WEIGHT_LOSS_PROGRAM_SKU)
    );

    return subHasGlp1Item;
  };

  readonly createSubscriptionMedicare = async (): Promise<{
    error?: string;
  }> => {
    return this.createSubscriptionFromSuggestedTreatmentPlan({
      couponCode: "FIRSTMONTH20",
      errorKey: "create_subscription_glp1_error",
      requiredItems: [MEDICARE_PROGRAM_SKU]
    });
  };

  readonly createSubscriptionGlp1 = async (): Promise<{ error?: string }> => {
    return this.createSubscriptionFromSuggestedTreatmentPlan({
      couponCode: "FIRSTMONTH20",
      errorKey: "create_subscription_glp1_error",
      requiredItems: [WEIGHT_LOSS_PROGRAM_SKU]
    });
  };

  readonly createSubscriptionFromSuggestedTreatmentPlan = async (options: {
    couponCode?: string;
    errorKey: string;
    requiredItems?: string[];
  }): Promise<{ error?: string }> => {
    const { couponCode } = options;
    try {
      const sub = this.getFullSubscription([
        SubscriptionDetailsResponse.status.DRAFT
      ]);

      const hasRequiredItems = options.requiredItems
        ? options.requiredItems.every((item) =>
            sub?.purchaseItems.some((purchaseItem) => purchaseItem.sku === item)
          )
        : false;

      if (hasRequiredItems && sub?.id) {
        await this.applySubscriptionCoupon(sub.id, couponCode);
        await this.loadAllSubscriptions();
        return {};
      }

      const data = await apiMiddleware.cachedSuggestTreatmentPlan();
      const isEligible =
        data.eligibility ===
        GetSuggestedTreatmentPlanResponse.eligibility.ELIGIBLE;

      const purchaseItems = data.purchaseItems ?? [];
      const requiredItems = options.requiredItems ?? [];
      const allRequiredItemsPresent = requiredItems.every((item) => {
        return purchaseItems.some((purchaseItem) => purchaseItem.sku === item);
      });

      if (!isEligible || !allRequiredItemsPresent) {
        const missingItems = requiredItems.filter(
          (item) =>
            !purchaseItems.some((purchaseItem) => purchaseItem.sku === item)
        );
        const error = `${options.errorKey}_missing_items: ${missingItems.join(
          ", "
        )}`;
        reportErrorSentry(new Error(error));
        return {
          error
        };
      }

      if (data.purchaseItems) {
        await this.initSubscription({
          renewalInterval: SubscriptionInterval.quarterly,
          couponCode,
          useTreatmentPlan: true,
          paymentInterval: SubscriptionInterval.monthly,
          items: data.purchaseItems.map((item) => item.sku)
        });

        await this.loadAllSubscriptions();
      }
    } catch (error) {
      reportErrorSentry(error);
      return { error: options.errorKey };
    }

    return {};
  };

  checkAllMedicalDataCollected = async (): Promise<boolean> => {
    try {
      const data = await apiMiddleware.cachedSuggestTreatmentPlan();
      let isEligible = [
        GetSuggestedTreatmentPlanResponse.eligibility.ELIGIBLE,
        GetSuggestedTreatmentPlanResponse.eligibility.INELIGIBLE
      ].includes(data.eligibility);

      if (!isEligible) {
        const dataNeededReasons = data.dataNeededReasons ?? [];

        const onlyAuthNeeded =
          dataNeededReasons.length === 1 &&
          dataNeededReasons[0] === "ANONYMOUS";

        if (onlyAuthNeeded) {
          isEligible = true;
        }
      }

      return isEligible;
    } catch (error) {
      reportErrorSentry(error);
      return false;
    }
  };

  get coveredByInsurance(): boolean | undefined {
    if (this.state.allSubscriptions === undefined) return undefined;

    const coveredItemsSkus = [
      PRESCRYPTIVE_PROGRAM_ITEM_SKU,
      MEDICARE_PROGRAM_SKU
    ];

    const filtered = coveredItemsSkus.filter(
      (sku) =>
        this.filterAllSubscriptions({
          status: [
            SubscriptionDetailsResponse.status.ACTIVE,
            SubscriptionDetailsResponse.status.IN_REVIEW
          ],
          items: [sku],
          looseCheck: true
        }).length > 0
    );

    return filtered.length > 0;
  }

  checkUserHasBoughtSubscription = async (): Promise<boolean> => {
    await this.loadAllSubscriptions();
    const sub = this.getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE,
      SubscriptionDetailsResponse.status.IN_REVIEW
    ]);
    addSentryBreadcrumb(
      "auth",
      `checkUserHasBoughtSubscription: ${Boolean(sub)}`,
      "info",
      {
        purchaseItems: sub?.purchaseItems.map((item) => item.sku),
        status: sub?.status
      }
    );
    return Boolean(sub);
  };

  checkUserIsEligible = async (funnelKey?: string): Promise<boolean> => {
    for (const s of this.state.allSubscriptions ?? []) {
      for (const item of s.purchaseItems) {
        if (item.metadata["partner.funnel"] === funnelKey) {
          return true;
        }
      }
    }

    return false;
  };

  private getEligibilityForCurrentPartnerPromise: Promise<GetSuggestedTreatmentPlanResponse | null> | null =
    null;

  readonly getEligibilityForCurrentPartner =
    async (): Promise<GetSuggestedTreatmentPlanResponse | null> => {
      let promise = this.getEligibilityForCurrentPartnerPromise;

      if (!promise) {
        promise = new Promise<GetSuggestedTreatmentPlanResponse | null>(
          (resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-deprecated
            void TreatmentPlanControllerService.suggestTreatmentPlan()
              .then((response) => {
                resolve(response.data);
              })
              .catch((e) => {
                reject(e as Error);
              })
              .finally(() => {
                this.getEligibilityForCurrentPartnerPromise = null;
              });
          }
        );
        this.getEligibilityForCurrentPartnerPromise = promise;
      }

      try {
        return await promise;
      } catch (e) {
        reportErrorSentry(e);
        return null;
      }
    };

  extractPayerInfo = (
    filter: {
      status?: SubscriptionDetailsResponse.status[];
    } = {}
  ): {
    lastSignupFunnel?: FunnelKey;
    payerIsCashPay?: boolean;
  } => {
    const activeSub = this.filterAllSubscriptions({
      status: filter.status ?? [SubscriptionDetailsResponse.status.ACTIVE],
      looseCheck: true
    });
    const draftSub = this.filterAllSubscriptions({
      status: filter.status ?? [SubscriptionDetailsResponse.status.DRAFT],
      looseCheck: true
    });

    const useSub = [...activeSub, ...draftSub];

    let lastSignupFunnel: FunnelKey | undefined = undefined;
    let payerIsCashPay: boolean | undefined = undefined;
    useSub.forEach((sub) => {
      for (const item of sub.purchaseItems) {
        if (item.metadata["partner.payer"]) {
          payerIsCashPay = item.metadata["partner.payer"] === PayerId.CASH_PAY;
        }

        if (item.metadata["partner.funnel"]) {
          lastSignupFunnel = item.metadata["partner.funnel"] as FunnelKey;
        } else if (!lastSignupFunnel && payerIsCashPay) {
          const isActiveSub =
            sub.status === SubscriptionDetailsResponse.status.ACTIVE;
          if (!isActiveSub) {
            lastSignupFunnel = FunnelKey.universal;
          }
        }
      }
    });

    return {
      lastSignupFunnel,
      payerIsCashPay
    };
  };

  getUnfinishedStepPath: () => Promise<string | undefined> = async () => {
    const payerInfo = this.extractPayerInfo();

    if (!payerInfo.lastSignupFunnel || this.hasInactiveSubscription) {
      return;
    }

    const signupCubit = new SignupCustomBloc({
      parameters: {
        campaign: payerInfo.lastSignupFunnel
      },
      options: { disableInit: true }
    });

    const step = await signupCubit.findFirstIncompleteStep(true);
    const foundCheckoutStep = step?.path === "checkout";

    if ((foundCheckoutStep && this.hasActiveSubscription) || !step) {
      return;
    }

    return `/signup/${payerInfo.lastSignupFunnel}/${step.path}`;
  };

  get activeSubscriptionPayerId(): PayerId | string | undefined {
    const activeSub = this.getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE
    ]);

    if (!activeSub) return undefined;

    const partnerPayerId = activeSub.purchaseItems.find((item) =>
      Boolean(item.metadata["partner.payer"])
    )?.metadata["partner.payer"] as PayerId | string | undefined;

    return partnerPayerId;
  }

  get activeSubscriptionSupportsHybridApp(): boolean {
    const partnerPayerId = this.activeSubscriptionPayerId;

    // yes if no payerId is found
    if (!partnerPayerId) return true;

    const payerIdsNotSupported = [PayerId.TRANSCARENT] as const;

    // yes if payerId is not in the list of unsupported payerIds, no otherwise
    return !payerIdsNotSupported.includes(partnerPayerId);
  }

  get activeSubscriptionHasBranding(): PayerId | false {
    const partnerPayerId = this.activeSubscriptionPayerId as
      | PayerId
      | undefined;
    if (!partnerPayerId) return false;
    const payerIdsWithBranding = [""] as const;
    const hasBranding = payerIdsWithBranding.includes(partnerPayerId);
    return hasBranding ? partnerPayerId : false;
  }

  get activeSubscriptionDetails(): {
    partnerFunnel?: FunnelKey;
    cashPay?: boolean;
  } {
    const activeSub = this.getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE
    ]);

    if (!activeSub) return {};

    const partnerFunnel = activeSub.purchaseItems.find((item) =>
      Boolean(item.metadata["partner.funnel"])
    )?.metadata["partner.funnel"] as FunnelKey | undefined;

    const cashPay =
      activeSub.purchaseItems.find((item) =>
        Boolean(item.metadata["partner.payer"])
      )?.metadata["partner.payer"] === PayerId.CASH_PAY;

    return {
      partnerFunnel,
      cashPay
    };
  }
}
