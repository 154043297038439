import { useBlocNext } from "@blac/react";
import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import UserCubit, {
  ProfileProgramMembership
} from "src/state/UserCubit/UserCubit";
import { useBloc } from "src/state/state";
import type { SignupSuccessPageProps } from "src/ui/components/SignupCustomContent/SignupSuccessPage";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import Translate from "src/ui/components/Translate/Translate";

const StartAssessmentButton: FC = () => {
  const [, { nextStep }] = useBlocNext(SignupCustomBloc);

  return (
    <nine-button
      onClick={() => {
        void nextStep();
      }}
    >
      <Translate msg="startAssessment" />
    </nine-button>
  );
};

const SubtitleWithProgramContext: FC = () => {
  const [, { programMemberships }] = useBloc(UserCubit);
  const selectefProgram = programMemberships?.find((p) => p.program)?.program;
  const program =
    selectefProgram ?? ProfileProgramMembership.ALLINONE_HEALTHCARE_CONCIERGE;

  return (
    <Translate
      msg="checkout.success.subtitle"
      variables={{
        program: translate(`program.title_${program}`)
      }}
    />
  );
};

const genericEnrollCheckoutSuccessScreenProps = (): SignupSuccessPageProps => ({
  title: <Translate msg="checkout.success.title" />,
  subtitle: <SubtitleWithProgramContext />,
  titleSpacer: false,
  steps: [
    {
      status: "in-progress",
      title: <Translate msg="task.medicalAssessment" />,
      subtitle: <Translate msg="task.completeMedicalAssessment" />,
      content: <StartAssessmentButton />
    },
    { status: "to-do", title: <Translate msg="task.onboardingCall" /> },
    { status: "to-do", title: <Translate msg="task.initialLabTest" /> },
    { status: "to-do", title: <Translate msg="task.medicalReview" /> }
  ]
});

export default genericEnrollCheckoutSuccessScreenProps;
