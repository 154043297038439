import { DataFieldProps } from "atom/datafield/dataFieldComponents";
import {
  CalendarDate,
  CalendarDateTime,
  ZonedDateTime
} from "@internationalized/date";
import { error, warn } from "lib/log";
import { requestIdleCallback } from "lib/requestIdleCallback";
import { Cubit } from "blac-next";
import React, { ReactNode } from "react";
import { DateValue } from "react-aria-components";
import { z } from "zod";

import { DataFieldDateProps } from "molecule/datafield/DataFieldDate";

import { CheckboxGroupProps } from "molecule/checkbox/CheckboxGroup";
import { Key, DateRange } from "react-aria";
import { DateRangePickerProps } from "molecule/daterangepicker/DateRangePicker";
import { DatePickerProps } from "molecule/datepicker/DatePicker";

export type FormSchemaType = z.ZodObject<z.ZodRawShape>;
export type FormError = z.ZodIssue;

export type FormKey<S extends FormSchemaType> = keyof z.infer<S>;
export type FormValueType<S extends FormSchemaType> = z.infer<S>[FormKey<S>];

export type FormValues<S extends FormSchemaType> = z.infer<S>;
export type FormValuesPartial<S extends FormSchemaType> = Partial<
  FormValues<S>
>;

export type FormErrors<S extends FormSchemaType> = Partial<
  Record<FormKey<S>, FormError[]>
>;

export type AutoFormBlocState<S extends FormSchemaType> = {
  dirty: boolean;
  values: FormValuesPartial<S>;
  errors: FormErrors<S>;
  schema?: S;
};

export type AutoFormRegisterProps<T = DataFieldProps> = {
  name: string;
  defaultValue: T extends DataFieldDateProps
    ? DateValue
    : T extends DateRangePickerProps<DateValue>
      ? DateRangePickerProps<DateValue>["defaultValue"]
      : T extends DatePickerProps<CalendarDate | CalendarDateTime>
        ? DatePickerProps<CalendarDate | CalendarDateTime>["defaultValue"]
        : T extends CheckboxGroupProps
          ? string[]
          : string;
  onChangeCapture?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorParser?: (props: T) => string;
  error?: string | z.ZodIssue;
  isInvalid?: boolean;
  required?: boolean;
  onDataValid?: (valid: boolean) => void;
};

export type FieldPropsFn<S extends FormSchemaType> = (
  name: FormKey<S>,
  options?: FieldPropsOptions
) => AutoFormRegisterProps;

export type AutoFormControlsActions<S extends FormSchemaType> = {
  getValues: () => FormValuesPartial<S>;
  setValue: (name: FormKey<S>, value: FormValueType<S>) => void;
  setValues: (values: Partial<FormValuesPartial<S>>) => void;
  reset: () => void;
  dirty: boolean;
};

export interface FormFieldBaseState {
  setValue: (value: string) => void;
}

export interface DropdownState {
  setValue: (value: Key) => void;
}

export interface AutocompleteState {
  setValue: (value: Key) => void;
}

export interface DateFieldState {
  setValue: (value: DateValue) => void;
}

export interface CheckboxGroupState {
  setValue: (value: string[]) => void;
}

export interface DateRangePickerState {
  setValue: (value: DateRange) => void;
}

export type FormFieldState =
  | FormFieldBaseState
  | DateFieldState
  | DropdownState
  | CheckboxGroupState
  | DateRangePickerState;

export type AutoFormProps<S extends FormSchemaType> = {
  schema: S;
  onSubmit?: (
    data: FormValues<S>,
    controls: AutoFormControlsActions<S>
  ) => void;
  children?: ReactNode;
  initialValue?: FormValuesPartial<S>;
  errorParser?: (props: DataFieldProps) => string;
  validateOnChange?: boolean;
  onChange?: (
    key: FormKey<S>,
    value: FormValueType<S>,
    form: {
      data: FormValuesPartial<S>;
      errors: FormErrors<S>;
      controls: AutoFormControlsActions<S>;
    }
  ) => void;
  contextId?: string;
};

interface FieldPropsOptions {
  type?:
    | typeof Number
    | typeof String
    | typeof ZonedDateTime
    | typeof Array<string>;
  ref?: { current: FormFieldState | null };
}

export default class AutoFormBloc<S extends FormSchemaType> extends Cubit<
  AutoFormBlocState<S>,
  AutoFormProps<S>
> {
  constructor() {
    super({ values: {}, errors: {}, dirty: false });
  }

  registeredFields: Set<string> = new Set();
  fieldTypes: Partial<Record<FormKey<S>, FieldPropsOptions["type"]>> = {};
  fieldRefs: Partial<Record<FormKey<S>, FieldPropsOptions["ref"]>> = {};

  registerAutoFormField: FieldPropsFn<S> = (key, options = {}) => {
    const { type = String, ref } = options;
    const name = String(key);
    this.fieldTypes[key] = type;
    this.fieldRefs[key] = ref;

    const defaultValue =
      (this.props?.initialValue as Record<string, string>)?.[name] ?? "";

    const rtProps = {
      name,
      defaultValue,
      errorParser: this.props?.errorParser
    } satisfies AutoFormRegisterProps;

    this.patch({ values: { ...this.state.values, [name]: defaultValue } });

    if (!this.props?.schema) {
      return rtProps;
    }

    const fieldSchema = this.props?.schema.shape[name];

    if (!fieldSchema) {
      warn(`AutoForm: schema does not contain field with the name: "${name}"`, {
        props: this.props,
        name
      });
      return {} as AutoFormRegisterProps;
    }

    this.registeredFields.add(name);
    return rtProps;
  };

  unregisterAutoFormField = (name: string) => {
    this.registeredFields.delete(name);
    this.patch({ values: { ...this.state.values, [name]: undefined } });
  };

  // TODO: Change argument to schema
  setSchema = (props: AutoFormProps<S>) => {
    if (props.schema && this.state.schema !== props.schema) {
      this.patch({ schema: props.schema });
      requestIdleCallback(() => this.checkForm());
    }
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (Object.keys(this.state.errors).length > 0) {
      this.patch({ errors: {} });
    }
    const data = this.state.values;

    // update types
    for (const key in data) {
      const type = this.fieldTypes[key as FormKey<S>];
      if (type === Number) {
        data[key] = Number(data[key]) as FormValueType<S>;
      }
    }

    try {
      this.state.schema?.parse(data);
      this.props?.onSubmit?.(data, this.formControls);
    } catch (e) {
      this.checkForm({ err: true });
      if (e instanceof z.ZodError) {
        console.error(e.issues);
        warn("AutoForm: form submission failed", e.issues);
        for (const issue of e.issues) {
          const simplePath = issue.path.filter((p) => typeof p === "string");
          const path = simplePath.join(".") as keyof FormKey<S>;
          // TODO: fix type casting
          const current = (this.state.errors[path] ?? []) as unknown as (
            | z.ZodIssue
            | undefined
          )[];
          const newIssues = issue;

          if (current[0]?.code !== newIssues?.code) {
            this.patch({
              errors: { ...this.state.errors, [path]: [issue] }
            });
          }
        }
      }
    }
  };

  checkForm = (
    options: {
      err?: boolean;
    } = {}
  ) => {
    const notify = options.err ? error : warn;
    const missingFields = Object.keys(this.state.schema?.shape ?? {}).filter(
      (name) => {
        const shape = this.state.schema?.shape[name];
        const isRequired = !shape?.isOptional() && !shape?.isNullable();

        if (!isRequired) return false;

        const missing = this.registeredFields.has(name);
        return !missing;
      }
    );

    if (missingFields.length > 0) {
      notify(
        `AutoForm: schema contains required fields that are not registered: ${missingFields.join(
          ", "
        )}`
      );
    }
  };

  handleChange = (key: FormKey<S>, value: FormValueType<S>) => {
    const currentValues = this.state.values[key];
    if (currentValues === value) return;

    const data = { ...this.state.values, [key]: value } as FormValues<S>;
    // remove empty strings
    for (const key in data) {
      const emptyString = data[key] === "";
      const emptyArray = Array.isArray(data[key]) && data[key].length === 0;
      if (emptyString || emptyArray) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete data[key];
      }
    }

    this.patch({
      values: data
    });

    setTimeout(() => {
      this.props?.onChange?.(key, value, {
        data: this.state.values,
        errors: this.state.errors,
        controls: this.formControls
      });
    });
  };

  getValues = (): typeof this.state.values => {
    return this.state.values;
  };

  get formControls(): AutoFormControlsActions<S> {
    return {
      getValues: this.getValues,
      setValue: this.setValue,
      setValues: this.setValues,
      reset: this.reset,
      dirty: this.state.dirty
    } satisfies AutoFormControlsActions<S>;
  }

  setValue = <K extends FormKey<S>>(name: K, value: FormValues<S>[K]) => {
    try {
      const currentValue = this.state.values[name];
      if (currentValue === value) return;

      const newValues = { ...this.state.values, [name]: value };

      this.patch({
        values: newValues,
        dirty: true
      });

      const ref = this.fieldRefs[name];

      if (ref?.current && typeof value === "undefined") {
        const state = ref.current;
        state.setValue(value);
      }
    } catch (e) {
      console.log("AutoFormBloc.setValue error", name, value);
      console.error(e);
    }
  };

  setValues = (values: Partial<typeof this.state.values>) => {
    try {
      const newValues = { ...this.state.values, ...values };

      this.patch({
        values: newValues,
        dirty: true
      });

      for (const [key, value] of Object.entries(values)) {
        const ref = this.fieldRefs[key as FormKey<S>];

        if (ref && ref.current && value) {
          const state = ref.current;
          state.setValue(value as FormValueType<S>);
        }
      }
    } catch (e) {
      console.log("AutoFormBloc.setValues error", values);
      console.error(e);
    }
  };

  reset = () => {
    this.patch({
      values: this.props?.initialValue ?? {},
      errors: {},
      dirty: false
    });

    for (const [key] of Object.entries(this.state.values)) {
      const ref = this.fieldRefs[key as FormKey<S>];

      if (ref && ref.current) {
        const state = ref.current;
        const val = this.props?.initialValue?.[key as FormKey<S>];
        if (val) state.setValue(val);
      }
    }
  };
}
